// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Router from "@reach/router";
import * as SVG$GlazedWebsite from "../../../shared/SVG.js";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Button$GlazedWebsite from "../../../shared/Button.js";
import * as Heading$GlazedWebsite from "../../../Heading.js";
import * as Routing$GlazedWebsite from "../../../shared/Routing.js";
import * as BaseModal$GlazedWebsite from "../../../shared/modals/BaseModal.js";
import * as MobileFooterLayout$GlazedWebsite from "../../../MobileFooterLayout.js";
import * as LogoGlazedOutlineSvg from "static/images/logo-glazed-outline.svg";
import * as DeveloperBackgroundImage$GlazedWebsite from "./DeveloperBackgroundImage.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDark)),
      /* [] */0
    ]);

var contentWrapper = Curry._1(Css.style, /* :: */[
      Css.alignItems(/* stretch */-162316795),
      /* [] */0
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(Css.rem(4.1875), Css.rem(1.25), Css.rem(4.1875), Css.rem(1.25)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.justifyContent(/* flexEnd */924268066),
              /* :: */[
                Css.flex3(1, 0, /* auto */-1065951377),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.justifyContent(/* flexStart */662439529),
                        /* :: */[
                          Css.padding4(Css.rem(6.25), Css.rem(2.5), Css.rem(6.25), Css.rem(4.75)),
                          /* [] */0
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var backgroundImage = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.width(Css.pct(100)),
              /* :: */[
                Css.maxWidth(Css.pct(90)),
                /* :: */[
                  Css.zIndex(0),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.maxWidth(Css.rem(64)),
                          /* :: */[
                            Css.left(/* unset */653193961),
                            /* [] */0
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var developerBackgroundImage = Curry._1(Css.merge, /* :: */[
      backgroundImage,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.opacity(0.6),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var overlayImage = Curry._1(Css.merge, /* :: */[
      backgroundImage,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.opacity(0.1),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var logoWrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.padding2(Css.rem(1.25), Css.rem(1.25)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.display(/* none */-922086728),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var logo = Curry._1(Css.style, /* :: */[
      Css.height(Css.rem(2.1875)),
      /* [] */0
    ]);

var closeButtonWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.position(/* absolute */-1013592457),
                /* :: */[
                  Css.top(/* zero */-789508312),
                  /* :: */[
                    Css.right(/* zero */-789508312),
                    /* [] */0
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var closeButton = Curry._1(Css.style, /* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.flex3(1, 1, /* auto */-1065951377),
        /* :: */[
          Css.width(Css.rem(8.5)),
          /* :: */[
            Css.height(Css.rem(8.5)),
            /* :: */[
              Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarker)),
              /* :: */[
                Css.fontFamily(/* `custom */[
                      1066567601,
                      Theme$GlazedWebsite.Fonts.heading
                    ]),
                /* :: */[
                  Css.fontSize(Css.rem(0.625)),
                  /* :: */[
                    Css.opacity(0.6),
                    /* :: */[
                      Css.color(Css.hex(Theme$GlazedWebsite.Colors.white)),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(/* `rem */[
              5691738,
              1.875
            ], /* zero */-789508312, /* `rem */[
              5691738,
              5.625
            ], /* `rem */[
              5691738,
              0.9375
            ]),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                1.125
              ]),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
            /* :: */[
              Css.before(/* :: */[
                    Css.contentRule(/* `text */[
                          -856044371,
                          ""
                        ]),
                    /* :: */[
                      Css.display(/* block */888960333),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.top(/* `px */[
                                25096,
                                0
                              ]),
                          /* :: */[
                            Css.left(/* `px */[
                                  25096,
                                  0
                                ]),
                            /* :: */[
                              Css.width(Css.px(130)),
                              /* :: */[
                                Css.height(Css.px(130)),
                                /* :: */[
                                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueDarker)),
                                  /* :: */[
                                    Css.opacity(0.1),
                                    /* :: */[
                                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                            Css.width(/* `px */[
                                                  25096,
                                                  258
                                                ]),
                                            /* :: */[
                                              Css.height(/* `px */[
                                                    25096,
                                                    258
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding4(/* `rem */[
                            5691738,
                            5.625
                          ], /* zero */-789508312, /* `rem */[
                            5691738,
                            1.875
                          ], /* `rem */[
                            5691738,
                            0.9375
                          ]),
                      /* :: */[
                        Css.width(/* auto */-1065951377),
                        /* :: */[
                          Css.fontSize(/* `rem */[
                                5691738,
                                2
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var descriptions = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.padding4(/* zero */-789508312, Css.rem(3.125), /* zero */-789508312, Css.rem(0.9375)),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.maxWidth(Css.rem(37.5)),
                  /* :: */[
                    Css.flex3(1, 0, /* auto */-1065951377),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var shortDescription = Curry._1(Css.style, /* :: */[
      Css.lineHeight(/* `abs */[
            4845682,
            2
          ]),
      /* :: */[
        Css.fontSize(Css.rem(0.875)),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.text
              ]),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
            /* :: */[
              Css.paddingBottom(Css.rem(0.625)),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.order(2),
                      /* :: */[
                        Css.flex3(1, 0, /* auto */-1065951377),
                        /* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* :: */[
                              Css.justifyContent(/* flexEnd */924268066),
                              /* :: */[
                                Css.paddingLeft(Css.rem(6.25)),
                                /* :: */[
                                  Css.fontSize(Css.rem(2)),
                                  /* :: */[
                                    Css.lineHeight(/* `abs */[
                                          4845682,
                                          1.5
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var description = Curry._1(Css.style, /* :: */[
      Css.lineHeight(/* `abs */[
            4845682,
            2
          ]),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.white)),
          /* :: */[
            Css.opacity(0.7),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.order(1),
                    /* :: */[
                      Css.fontSize(Css.rem(1.5)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var skillsAndLinkedIn = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* rowReverse */378951208),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.justifyContent(/* spaceBetween */516682146),
            /* :: */[
              Css.padding4(/* `rem */[
                    5691738,
                    1.875
                  ], /* zero */-789508312, /* `rem */[
                    5691738,
                    1.875
                  ], /* `rem */[
                    5691738,
                    0.9375
                  ]),
              /* :: */[
                Css.fontSize(Css.rem(0.625)),
                /* :: */[
                  Css.zIndex(0),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.padding(/* zero */-789508312),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var skills = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
      /* :: */[
        Css.opacity(0.4),
        /* :: */[
          Css.fontFamily(/* `custom */[
                1066567601,
                Theme$GlazedWebsite.Fonts.heading
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.display(/* none */-922086728),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var desktopSkills = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* block */888960333),
              /* :: */[
                Css.position(/* absolute */-1013592457),
                /* :: */[
                  Css.top(Css.pct(50)),
                  /* :: */[
                    Css.right(Css.rem(2.5)),
                    /* :: */[
                      Css.minWidth(Css.rem(9.375)),
                      /* :: */[
                        Css.transform(Css.translateY(Css.pct(-50))),
                        /* :: */[
                          Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                          /* :: */[
                            Css.whiteSpace(/* preLine */538440119),
                            /* :: */[
                              Css.fontSize(Css.rem(1)),
                              /* :: */[
                                Css.lineHeight(/* `abs */[
                                      4845682,
                                      2
                                    ]),
                                /* :: */[
                                  Css.opacity(0.6),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var linkedinLink = Curry._1(Css.style, /* :: */[
      Css.fontFamily(/* `custom */[
            1066567601,
            Theme$GlazedWebsite.Fonts.heading
          ]),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlue)),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.fontSize(Css.rem(0.75)),
                /* :: */[
                  Css.minWidth(Css.rem(9.375)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var mobileFooter = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.bottom(/* zero */-789508312),
        /* :: */[
          Css.left(/* zero */-789508312),
          /* :: */[
            Css.right(/* zero */-789508312),
            /* :: */[
              Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkestish)),
              /* :: */[
                Css.display(/* flex */-1010954439),
                /* :: */[
                  Css.alignItems(/* center */98248149),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.display(/* none */-922086728),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var mobileCloseButton = Curry._1(Css.style, /* :: */[
      Css.padding(Css.rem(1.25)),
      /* :: */[
        Css.background(/* none */-922086728),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
          /* :: */[
            Css.fontFamily(/* `custom */[
                  1066567601,
                  Theme$GlazedWebsite.Fonts.heading
                ]),
            /* :: */[
              Css.fontSize(Css.rem(0.625)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  contentWrapper: contentWrapper,
  content: content,
  backgroundImage: backgroundImage,
  developerBackgroundImage: developerBackgroundImage,
  overlayImage: overlayImage,
  logoWrapper: logoWrapper,
  logo: logo,
  closeButtonWrapper: closeButtonWrapper,
  closeButton: closeButton,
  heading: heading,
  descriptions: descriptions,
  shortDescription: shortDescription,
  description: description,
  skillsAndLinkedIn: skillsAndLinkedIn,
  skills: skills,
  desktopSkills: desktopSkills,
  linkedinLink: linkedinLink,
  mobileFooter: mobileFooter,
  mobileCloseButton: mobileCloseButton
};

var teamSlideLink = "/#team";

function DeveloperModal$CloseButton(Props) {
  var close = Props.close;
  return React.createElement(Gatsby.Link, {
              to: teamSlideLink,
              className: closeButtonWrapper,
              children: React.createElement(Button$GlazedWebsite.make, {
                    children: "< back team",
                    className: closeButton
                  }),
              onClick: close
            });
}

var CloseButton = {
  make: DeveloperModal$CloseButton
};

function DeveloperModal$MobileFooter(Props) {
  var close = Props.close;
  return React.createElement(MobileFooterLayout$GlazedWebsite.make, {
              className: mobileFooter,
              children: React.createElement(Gatsby.Link, {
                    to: teamSlideLink,
                    children: React.createElement(Button$GlazedWebsite.make, {
                          children: "< back team",
                          className: mobileCloseButton
                        }),
                    onClick: close
                  })
            });
}

var MobileFooter = {
  make: DeveloperModal$MobileFooter
};

function DeveloperModal(Props) {
  var modalId = Props.modalId;
  var onClose = Props.onClose;
  var _inOpt = Props.in;
  var onExitedOpt = Props.onExited;
  var developer = Props.developer;
  var _in = _inOpt !== undefined ? _inOpt : true;
  var onExited = onExitedOpt !== undefined ? onExitedOpt : (function (param) {
        return /* () */0;
      });
  var $$location = Router.useLocation();
  var exit = React.useCallback((function (param) {
          Curry._1(onExited, /* () */0);
          var match = new URLSearchParams($$location.search).get("modal");
          if ((match == null) || match !== "team") {
            return /* () */0;
          } else {
            return Routing$GlazedWebsite.navigate({
                        preventDefaultScrollBehavior: true
                      }, undefined, teamSlideLink);
          }
        }), /* tuple */[
        onExited,
        $$location
      ]);
  var handleCloseButtonClick = React.useCallback((function ($$event) {
          $$event.preventDefault();
          return Curry._1(onClose, /* () */0);
        }), [onClose]);
  return React.createElement(BaseModal$GlazedWebsite.make, {
              children: null,
              modalId: modalId,
              onClose: onClose,
              scrollContainerClassName: wrapper,
              contentWrapperClassName: contentWrapper,
              contentClassName: content,
              in: _in,
              onExited: exit
            }, React.createElement(DeveloperBackgroundImage$GlazedWebsite.make, {
                  developerPhotoKey: developer.key,
                  className: developerBackgroundImage
                }), React.createElement(DeveloperBackgroundImage$GlazedWebsite.make, {
                  developerPhotoKey: "overlay",
                  className: overlayImage
                }), React.createElement(Heading$GlazedWebsite.make, {
                  level: /* H1 */0,
                  className: heading,
                  children: $$Array.mapi((function (index, name) {
                          return React.createElement("div", {
                                      key: String(index)
                                    }, name);
                        }), developer.name.split(" "))
                }), React.createElement("div", {
                  className: descriptions
                }, React.createElement(Heading$GlazedWebsite.make, {
                      level: /* H3 */2,
                      className: shortDescription,
                      children: developer.shortDescription
                    }), React.createElement("p", {
                      className: description
                    }, developer.description)), React.createElement("div", {
                  className: skillsAndLinkedIn
                }, React.createElement("a", {
                      className: linkedinLink,
                      href: developer.linkedIn,
                      rel: "nofollow",
                      target: "_blank"
                    }, "> LinkedIn"), React.createElement("p", {
                      className: skills
                    }, "{ " + ($$Array.fold_left((function (result, skill) {
                              return "" + (String(result) + (" " + (String(skill) + "")));
                            }), "", developer.skills) + " }")), React.createElement("p", {
                      className: desktopSkills
                    }, "{ \n" + ($$Array.fold_left((function (result, skill) {
                              return "" + (String(result) + (" // " + (String(skill) + "\n")));
                            }), "", developer.skills) + " }"))), React.createElement("div", {
                  className: logoWrapper
                }, React.createElement(SVG$GlazedWebsite.make, {
                      className: logo,
                      asset: LogoGlazedOutlineSvg.default
                    })), React.createElement(DeveloperModal$CloseButton, {
                  close: handleCloseButtonClick
                }), React.createElement(DeveloperModal$MobileFooter, {
                  close: handleCloseButtonClick
                }));
}

var make = DeveloperModal;

var $$default = DeveloperModal;

export {
  Styles ,
  teamSlideLink ,
  CloseButton ,
  MobileFooter ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
