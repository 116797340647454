// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.before(/* :: */[
              Css.contentRule(/* `text */[
                    -856044371,
                    ""
                  ]),
              /* :: */[
                Css.display(/* block */888960333),
                /* :: */[
                  Css.paddingTop(Css.pct(100)),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  content: content
};

function Square(Props) {
  var children = Props.children;
  var className = Props.className;
  var contentClassName = Props.contentClassName;
  var onMouseEnter = Props.onMouseEnter;
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  var tmp$2 = { };
  var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        content,
        /* :: */[
          contentClassName,
          /* [] */0
        ]
      ]);
  if (tmp$3 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement("div", tmp, React.createElement("div", tmp$2, children !== undefined ? Caml_option.valFromOption(children) : null));
}

var make = Square;

var $$default = Square;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
