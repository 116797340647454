// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../../shared/Utils.js";

var cubeSize = /* `px */[
  25096,
  250
];

var cubeDimensions = /* `px */[
  25096,
  500
];

var cubePerspective = /* `px */[
  25096,
  2000
];

var Variables = {
  rawCubeSize: 250,
  cubeSize: cubeSize,
  cubeDimensions: cubeDimensions,
  cubePerspective: cubePerspective
};

var wrapper = Curry._1(Css.style, /* :: */[
      Css.perspective(cubePerspective),
      /* :: */[
        Css.perspectiveOrigin(cubeSize, cubeSize),
        /* :: */[
          Css.width(cubeDimensions),
          /* :: */[
            Css.height(cubeDimensions),
            /* :: */[
              Css.filter(/* :: */[
                    /* `blur */[
                      -1055309145,
                      /* `px */[
                        25096,
                        1
                      ]
                    ],
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var cube = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.transformStyle(/* preserve3d */589702045),
        /* :: */[
          Css.transformOrigin(cubeSize, cubeSize),
          /* [] */0
        ]
      ]
    ]);

var cubeFace = Curry._1(Css.style, /* :: */[
      Css.width(cubeDimensions),
      /* :: */[
        Css.height(cubeDimensions),
        /* :: */[
          Css.opacity(0.8),
          /* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.boxShadow(Css_Core.Shadow.box(/* zero */-789508312, /* zero */-789508312, Css.px(5), /* zero */-789508312, undefined, Css.rgba(83, 211, 255, 1))),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var front = Curry._1(Css.style, /* :: */[
      Css.transform(/* `translateZ */[
            106728780,
            cubeSize
          ]),
      /* :: */[
        Css.border(Css.px(1), /* solid */12956715, Css.rgba(83, 211, 255, 1)),
        /* [] */0
      ]
    ]);

var back = Curry._1(Css.style, /* :: */[
      Css.transforms(/* :: */[
            /* `translateZ */[
              106728780,
              /* `px */[
                25096,
                -250
              ]
            ],
            /* :: */[
              /* `rotateY */[
                -249738850,
                /* `deg */[
                  4995526,
                  180
                ]
              ],
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.border(Css.px(1), /* solid */12956715, Css.rgba(83, 211, 255, 0.2)),
        /* :: */[
          Css.transformOrigin(/* `percent */[
                -119887163,
                50
              ], /* `percent */[
                -119887163,
                50
              ]),
          /* [] */0
        ]
      ]
    ]);

var right = Curry._1(Css.style, /* :: */[
      Css.transforms(/* :: */[
            /* `translateX */[
              106728778,
              cubeSize
            ],
            /* :: */[
              /* `rotateY */[
                -249738850,
                /* `deg */[
                  4995526,
                  -270
                ]
              ],
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.transformOrigin(/* `percent */[
              -119887163,
              0
            ], /* `percent */[
              -119887163,
              100
            ]),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, Css.rgba(83, 211, 255, 1)),
          /* :: */[
            Css.borderLeftStyle(/* none */-922086728),
            /* [] */0
          ]
        ]
      ]
    ]);

var left = Curry._1(Css.style, /* :: */[
      Css.transforms(/* :: */[
            /* `translateX */[
              106728778,
              /* `px */[
                25096,
                -250
              ]
            ],
            /* :: */[
              /* `rotateY */[
                -249738850,
                /* `deg */[
                  4995526,
                  270
                ]
              ],
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.transformOrigin(/* `percent */[
              -119887163,
              50
            ], /* `percent */[
              -119887163,
              0
            ]),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, Css.rgba(83, 211, 255, 1)),
          /* :: */[
            Css.borderRightStyle(/* none */-922086728),
            /* [] */0
          ]
        ]
      ]
    ]);

var top = Curry._1(Css.style, /* :: */[
      Css.transforms(/* :: */[
            /* `translateY */[
              106728779,
              /* `px */[
                25096,
                -250
              ]
            ],
            /* :: */[
              /* `rotateX */[
                -249738851,
                /* `deg */[
                  4995526,
                  90
                ]
              ],
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.transformOrigin(/* `percent */[
              -119887163,
              0
            ], /* `percent */[
              -119887163,
              50
            ]),
        /* [] */0
      ]
    ]);

var bottom = Curry._1(Css.style, /* :: */[
      Css.transforms(/* :: */[
            /* `translateY */[
              106728779,
              cubeSize
            ],
            /* :: */[
              /* `rotateX */[
                -249738851,
                /* `deg */[
                  4995526,
                  -90
                ]
              ],
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.transformOrigin(/* `percent */[
              -119887163,
              100
            ], /* `percent */[
              -119887163,
              50
            ]),
        /* [] */0
      ]
    ]);

var Styles = {
  Variables: Variables,
  wrapper: wrapper,
  cube: cube,
  cubeFace: cubeFace,
  front: front,
  back: back,
  right: right,
  left: left,
  top: top,
  bottom: bottom
};

function Cube(Props) {
  var className = Props.className;
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = { };
  var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        cubeFace,
        /* :: */[
          front,
          /* [] */0
        ]
      ]);
  if (tmp$3 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(tmp$3);
  }
  var tmp$4 = { };
  var tmp$5 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        cubeFace,
        /* :: */[
          back,
          /* [] */0
        ]
      ]);
  if (tmp$5 !== undefined) {
    tmp$4.className = Caml_option.valFromOption(tmp$5);
  }
  var tmp$6 = { };
  var tmp$7 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        cubeFace,
        /* :: */[
          top,
          /* [] */0
        ]
      ]);
  if (tmp$7 !== undefined) {
    tmp$6.className = Caml_option.valFromOption(tmp$7);
  }
  var tmp$8 = { };
  var tmp$9 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        cubeFace,
        /* :: */[
          bottom,
          /* [] */0
        ]
      ]);
  if (tmp$9 !== undefined) {
    tmp$8.className = Caml_option.valFromOption(tmp$9);
  }
  var tmp$10 = { };
  var tmp$11 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        cubeFace,
        /* :: */[
          left,
          /* [] */0
        ]
      ]);
  if (tmp$11 !== undefined) {
    tmp$10.className = Caml_option.valFromOption(tmp$11);
  }
  var tmp$12 = { };
  var tmp$13 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        cubeFace,
        /* :: */[
          right,
          /* [] */0
        ]
      ]);
  if (tmp$13 !== undefined) {
    tmp$12.className = Caml_option.valFromOption(tmp$13);
  }
  return React.createElement("div", tmp, React.createElement("div", {
                  className: cube
                }, React.createElement("div", tmp$2), React.createElement("div", tmp$4), React.createElement("div", tmp$6), React.createElement("div", tmp$8), React.createElement("div", tmp$10), React.createElement("div", tmp$12)));
}

var make = Cube;

var $$default = Cube;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
