// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Belt_Map from "bs-platform/lib/es6/belt_Map.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Router from "@reach/router";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Utils$GlazedWebsite from "../../../shared/Utils.js";
import * as Square$GlazedWebsite from "../../../Square.js";
import * as Heading$GlazedWebsite from "../../../Heading.js";
import * as Routing$GlazedWebsite from "../../../shared/Routing.js";
import * as FullPageSlide$GlazedWebsite from "../../../FullPageSlide.js";
import * as DeveloperModal$GlazedWebsite from "./DeveloperModal.js";
import * as ModalsController$GlazedWebsite from "../../../shared/modals/ModalsController.js";
import * as IndexTeamSlideSquares$GlazedWebsite from "./IndexTeamSlideSquares.js";
import * as OpenSayHelloModalHook$GlazedWebsite from "../../../OpenSayHelloModalHook.js";
import * as DeveloperBackgroundImage$GlazedWebsite from "./DeveloperBackgroundImage.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.padding4(Css.rem(4.1875), Css.rem(2.5), Css.rem(4.1875), Css.rem(1.25)),
      /* :: */[
        Css.height(/* auto */-1065951377),
        /* :: */[
          Css.flex3(1, 0, /* auto */-1065951377),
          /* :: */[
            Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.padding2(Css.rem(6.25), Css.rem(4.75)),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.opacity(0.9),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              1.125
            ]),
        /* :: */[
          Css.lineHeight(/* `rem */[
                5691738,
                1.75
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                  Css.fontSize(Css.rem(2)),
                  /* :: */[
                    Css.lineHeight(Css.rem(3)),
                    /* :: */[
                      Css.width(Css.px(183)),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var grid = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* [] */0
      ]
    ]);

var square = Curry._1(Css.style, /* :: */[
      Css.flex3(0, 0, Css.pct(50)),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
              Css.flex3(0, 0, Css.pct(33.3333333)),
              /* [] */0
            ]),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.flex3(0, 0, Css.pct(25)),
                /* [] */0
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                  Css.flex3(0, 0, Css.pct(20)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var squareContent = Curry._1(Css.style, /* :: */[
      Css.padding(Css.rem(0.9375)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.padding2(Css.rem(2.1875), Css.rem(1.875)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var squareWithBorder = Curry._1(Css.style, /* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.rgba(105, 192, 254, 0.1)),
      /* [] */0
    ]);

var headingSquare = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
      /* :: */[
        Css.padding3(Css.pct(30), /* `rem */[
              5691738,
              0.9375
            ], /* zero */-789508312),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.padding3(Css.pct(30), /* `rem */[
                      5691738,
                      1.4375
                    ], /* zero */-789508312),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var link = Curry._1(Css.style, /* :: */[
      Css.fontFamily(/* `custom */[
            1066567601,
            Theme$GlazedWebsite.Fonts.heading
          ]),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              0.75
            ]),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlue)),
          /* [] */0
        ]
      ]
    ]);

var developerSquare = Curry._1(Css.style, /* :: */[
      Css.padding(/* zero */-789508312),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
          /* :: */[
            Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkestish)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.padding(/* zero */-789508312),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var developerSquareLink = Curry._1(Css.style, /* :: */[
      Css.padding(Css.rem(0.9375)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.flex3(1, 1, Css.rem(0.000000001)),
            /* :: */[
              Css.before(/* :: */[
                    Css.contentRule(/* `text */[
                          -856044371,
                          ""
                        ]),
                    /* :: */[
                      Css.position(/* absolute */-1013592457),
                      /* :: */[
                        Css.top(/* zero */-789508312),
                        /* :: */[
                          Css.right(/* zero */-789508312),
                          /* :: */[
                            Css.bottom(/* zero */-789508312),
                            /* :: */[
                              Css.left(/* zero */-789508312),
                              /* :: */[
                                Css.opacity(0),
                                /* :: */[
                                  Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDark)),
                                  /* :: */[
                                    Css.transition(300, undefined, undefined, "opacity"),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.hover(/* :: */[
                      Css.before(/* :: */[
                            Css.opacity(1),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.focus(/* :: */[
                        Css.before(/* :: */[
                              Css.opacity(1),
                              /* [] */0
                            ]),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.active(/* :: */[
                          Css.before(/* :: */[
                                Css.opacity(1),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                            Css.padding2(Css.rem(2.1875), Css.rem(1.875)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var developerSquareContent = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* flexEnd */924268066),
          /* :: */[
            Css.flex3(1, 1, Css.rem(0.000000001)),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
              /* :: */[
                Css.opacity(0.8),
                /* :: */[
                  Css.transition(300, undefined, undefined, "opacity"),
                  /* :: */[
                    Css.zIndex(0),
                    /* :: */[
                      Css.hover(/* :: */[
                            Css.opacity(1),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var developerBackgroundImageWrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* :: */[
                Css.opacity(0.4),
                /* :: */[
                  Css.transition(300, undefined, undefined, "opacity"),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.opacity(0.6),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var developerBackgroundImage = Curry._1(Css.style, /* :: */[
      Css.top(/* zero */-789508312),
      /* :: */[
        Css.right(/* zero */-789508312),
        /* :: */[
          Css.bottom(/* zero */-789508312),
          /* :: */[
            Css.left(/* zero */-789508312),
            /* [] */0
          ]
        ]
      ]
    ]);

var developerName = Curry._1(Css.style, /* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.paddingBottom(Css.rem(0.875)),
        /* :: */[
          Css.whiteSpace(/* preLine */538440119),
          /* [] */0
        ]
      ]
    ]);

var developerSkills = Curry._1(Css.style, /* :: */[
      Css.fontSize(Css.rem(0.625)),
      /* :: */[
        Css.fontFamily(/* `custom */[
              1066567601,
              Theme$GlazedWebsite.Fonts.heading
            ]),
        /* [] */0
      ]
    ]);

var sendApplication = Curry._1(Css.style, /* :: */[
      Css.flex3(1, 1, Css.rem(0.000000001)),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
        /* :: */[
          Css.fontSize(Css.rem(1)),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.5
                ]),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.phone, /* :: */[
                    Css.paddingTop(Css.rem(1)),
                    /* [] */0
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.fontSize(Css.rem(1.6)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var motto = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
      /* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.lineHeight(/* `abs */[
                4845682,
                1.5
              ]),
          /* :: */[
            Css.opacity(0.7),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.phone, /* :: */[
                    Css.paddingTop(Css.rem(1)),
                    /* [] */0
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.fontSize(Css.rem(1.6)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  heading: heading,
  grid: grid,
  square: square,
  squareContent: squareContent,
  squareWithBorder: squareWithBorder,
  headingSquare: headingSquare,
  link: link,
  developerSquare: developerSquare,
  developerSquareLink: developerSquareLink,
  developerSquareContent: developerSquareContent,
  developerBackgroundImageWrapper: developerBackgroundImageWrapper,
  developerBackgroundImage: developerBackgroundImage,
  developerName: developerName,
  developerSkills: developerSkills,
  sendApplication: sendApplication,
  motto: motto
};

function IndexTeamSlide$TeamSlideSquare(Props) {
  var children = Props.children;
  var contentClassName = Props.contentClassName;
  var tmp = {
    children: children,
    className: square
  };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        squareContent,
        /* :: */[
          contentClassName,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.contentClassName = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Square$GlazedWebsite.make, tmp);
}

var TeamSlideSquare = {
  make: IndexTeamSlide$TeamSlideSquare
};

function IndexTeamSlide$TitleSquare(Props) {
  var title = Props.title;
  return React.createElement(IndexTeamSlide$TeamSlideSquare, {
              children: React.createElement(Heading$GlazedWebsite.make, {
                    level: /* H1 */0,
                    className: heading,
                    children: title
                  }),
              contentClassName: Curry._1(Css.merge, /* :: */[
                    headingSquare,
                    /* :: */[
                      squareWithBorder,
                      /* [] */0
                    ]
                  ])
            });
}

var TitleSquare = {
  make: IndexTeamSlide$TitleSquare
};

function IndexTeamSlide$MottoSquare(Props) {
  var motto$1 = Props.motto;
  return React.createElement(IndexTeamSlide$TeamSlideSquare, {
              children: React.createElement(Heading$GlazedWebsite.make, {
                    level: /* H2 */1,
                    className: motto,
                    children: motto$1
                  }),
              contentClassName: squareWithBorder
            });
}

var MottoSquare = {
  make: IndexTeamSlide$MottoSquare
};

function IndexTeamSlide$SendApplicationSquare(Props) {
  var match = OpenSayHelloModalHook$GlazedWebsite.useOpenSayHelloModal(/* () */0);
  return React.createElement(IndexTeamSlide$TeamSlideSquare, {
              children: null,
              contentClassName: squareWithBorder
            }, React.createElement(Heading$GlazedWebsite.make, {
                  level: /* H2 */1,
                  className: sendApplication,
                  children: "<Your name could be here />"
                }), React.createElement(Gatsby.Link, {
                  to: match[0],
                  className: link,
                  children: "> send application",
                  onClick: match[1],
                  state: {
                    state: {
                      preventDefaultScrollBehavior: true
                    }
                  }
                }));
}

var SendApplicationSquare = {
  make: IndexTeamSlide$SendApplicationSquare
};

var selectedDeveloperQueryStringKey = "unit";

function developerLink(param) {
  return "/?modal=team&" + (String(selectedDeveloperQueryStringKey) + ("=" + (String(param.key) + "")));
}

function IndexTeamSlide$DeveloperSquare(Props) {
  var developer = Props.developer;
  var link = developerLink(developer);
  var onClick = React.useCallback((function (_event) {
          return Routing$GlazedWebsite.navigate({
                      preventDefaultScrollBehavior: true
                    }, undefined, link);
        }), [developer]);
  return React.createElement(IndexTeamSlide$TeamSlideSquare, {
              children: React.createElement(Gatsby.Link, {
                    to: link,
                    className: developerSquareLink,
                    children: null,
                    onClick: onClick,
                    replace: true,
                    state: {
                      state: {
                        preventDefaultScrollBehavior: true
                      }
                    }
                  }, React.createElement(DeveloperBackgroundImage$GlazedWebsite.make, {
                        developerPhotoKey: developer.key,
                        className: developerBackgroundImageWrapper,
                        gatsbyBackgroundImageClassName: developerBackgroundImage,
                        style: {
                          backgroundPosition: "top center",
                          marginTop: "10%",
                          backgroundSize: "auto 250%"
                        }
                      }), React.createElement("div", {
                        className: developerSquareContent
                      }, React.createElement(Heading$GlazedWebsite.make, {
                            level: /* H6 */5,
                            className: developerName,
                            children: developer.name.replace(" ", "\n")
                          }), React.createElement("p", {
                            className: developerSkills
                          }, "{ " + ($$Array.fold_left((function (result, skill) {
                                    return "" + (String(result) + (" // " + (String(skill) + "")));
                                  }), "", developer.skills) + " }")))),
              contentClassName: developerSquare
            });
}

var DeveloperSquare = {
  make: IndexTeamSlide$DeveloperSquare
};

var make = React.memo((function (Props) {
        var innerRef = Props.innerRef;
        var id = Props.id;
        var onResize = Props.onResize;
        var openedModalRef = React.useRef(undefined);
        var modalsAPI = ModalsController$GlazedWebsite.useContextAPI(/* () */0);
        var $$location = Router.useLocation();
        var selectedDeveloper = new URLSearchParams($$location.search).get(selectedDeveloperQueryStringKey);
        var selectedDeveloper$1 = (selectedDeveloper == null) ? undefined : Caml_option.some(selectedDeveloper);
        React.useEffect((function () {
                var match = openedModalRef.current;
                if (match !== undefined) {
                  Curry._1(modalsAPI.closeModal, match);
                }
                var match$1 = Belt_Option.flatMap(selectedDeveloper$1, (function (developerKey) {
                        return Belt_Map.get(IndexTeamSlideSquares$GlazedWebsite.developers, developerKey);
                      }));
                if (match$1 !== undefined) {
                  var developer = match$1;
                  var modal = Curry._1(modalsAPI.openModal, (function (modalId, onClose) {
                          return React.createElement(DeveloperModal$GlazedWebsite.make, {
                                      modalId: modalId,
                                      onClose: onClose,
                                      developer: developer
                                    });
                        }));
                  openedModalRef.current = modal;
                }
                return ;
              }), [selectedDeveloper$1]);
        var tmp = {
          children: React.createElement("div", {
                className: grid
              }, $$Array.mapi((function (index, square) {
                      if (typeof square === "number") {
                        return React.createElement(IndexTeamSlide$SendApplicationSquare, {
                                    key: String(index)
                                  });
                      } else {
                        switch (square.tag | 0) {
                          case /* TitleSquare */0 :
                              return React.createElement(IndexTeamSlide$TitleSquare, {
                                          title: square[0],
                                          key: String(index)
                                        });
                          case /* MottoSquare */1 :
                              return React.createElement(IndexTeamSlide$MottoSquare, {
                                          motto: square[0],
                                          key: String(index)
                                        });
                          case /* DeveloperSquare */2 :
                              var match = Belt_Map.get(IndexTeamSlideSquares$GlazedWebsite.developers, square[0]);
                              if (match !== undefined) {
                                return React.createElement(IndexTeamSlide$DeveloperSquare, {
                                            developer: match,
                                            key: String(index)
                                          });
                              } else {
                                return null;
                              }
                          
                        }
                      }
                    }), IndexTeamSlideSquares$GlazedWebsite.squares)),
          className: wrapper,
          onResize: onResize
        };
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (innerRef !== undefined) {
          tmp.innerRef = Caml_option.valFromOption(innerRef);
        }
        return React.createElement(FullPageSlide$GlazedWebsite.make, tmp);
      }));

var $$default = make;

export {
  Styles ,
  TeamSlideSquare ,
  TitleSquare ,
  MottoSquare ,
  SendApplicationSquare ,
  selectedDeveloperQueryStringKey ,
  developerLink ,
  DeveloperSquare ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
