// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Heading$GlazedWebsite from "../../../Heading.js";
import * as CaseStudiesSlideSquare$GlazedWebsite from "./CaseStudiesSlideSquare.js";

var number = Curry._1(Css.style, /* :: */[
      Css.fontFamily(/* `custom */[
            1066567601,
            Theme$GlazedWebsite.Fonts.heading
          ]),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              0.5
            ]),
        /* :: */[
          Css.opacity(0.3),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.fontSize(/* `rem */[
                          5691738,
                          0.625
                        ]),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var smallProjectName = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* flexEnd */924268066),
        /* :: */[
          Css.flex3(1, 1, /* `rem */[
                5691738,
                0.000001
              ]),
          /* :: */[
            Css.fontSize(/* `rem */[
                  5691738,
                  0.75
                ]),
            /* :: */[
              Css.opacity(0.7),
              /* :: */[
                Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.fontSize(/* `rem */[
                              5691738,
                              1.125
                            ]),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var bigProjectName = Curry._1(Css.style, /* :: */[
      Css.fontSize(/* `rem */[
            5691738,
            1.125
          ]),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
        /* :: */[
          Css.opacity(0.9),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.fontSize(/* `rem */[
                        5691738,
                        2
                      ]),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var projectArea = Curry._1(Css.style, /* :: */[
      Css.color(Css.white),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* normal */812216871),
          /* :: */[
            Css.letterSpacing(Css.pxFloat(0.31)),
            /* :: */[
              Css.lineHeight(Css.px(34)),
              /* :: */[
                Css.marginLeft(Css.px(30)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var link = Curry._1(Css.style, /* :: */[
      Css.fontFamily(/* `custom */[
            1066567601,
            Theme$GlazedWebsite.Fonts.heading
          ]),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              0.75
            ]),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlue)),
          /* [] */0
        ]
      ]
    ]);

var centeredContentWhenSelected = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flex3(1, 1, /* `rem */[
                5691738,
                0.000001
              ]),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* [] */0
          ]
        ]
      ]
    ]);

var area = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.paddingTop(/* `rem */[
              5691738,
              0.625
            ]),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                0.8125
              ]),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.5
                ]),
            /* :: */[
              Css.opacity(0.5),
              /* :: */[
                Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.display(/* block */888960333),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  number: number,
  smallProjectName: smallProjectName,
  bigProjectName: bigProjectName,
  projectArea: projectArea,
  link: link,
  centeredContentWhenSelected: centeredContentWhenSelected,
  area: area
};

function CaseStudySquare(Props) {
  var caseStudy = Props.caseStudy;
  var index = Props.index;
  var className = Props.className;
  var contentWrapperClassName = Props.contentWrapperClassName;
  var contentClassName = Props.contentClassName;
  var isSelected = Props.isSelected;
  var onMouseEnter = Props.onMouseEnter;
  var tmp = {
    children: null,
    contentWhenSelected: React.createElement(React.Fragment, {
          children: null
        }, React.createElement("p", {
              className: number
            }, "// 0" + String(index + 1 | 0)), React.createElement("div", {
              className: centeredContentWhenSelected
            }, React.createElement(Heading$GlazedWebsite.make, {
                  level: /* H2 */1,
                  className: bigProjectName,
                  children: caseStudy.name
                }), React.createElement("p", {
                  className: area
                }, caseStudy.area)), React.createElement(Gatsby.Link, {
              to: caseStudy.link,
              className: link,
              children: "> explore case"
            })),
    isSelected: isSelected
  };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (contentWrapperClassName !== undefined) {
    tmp.contentWrapperClassName = Caml_option.valFromOption(contentWrapperClassName);
  }
  if (contentClassName !== undefined) {
    tmp.contentClassName = Caml_option.valFromOption(contentClassName);
  }
  if (onMouseEnter !== undefined) {
    tmp.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  return React.createElement(CaseStudiesSlideSquare$GlazedWebsite.make, tmp, React.createElement("p", {
                  className: number
                }, "// 0" + String(index + 1 | 0)), React.createElement(Heading$GlazedWebsite.make, {
                  level: /* H4 */3,
                  className: smallProjectName,
                  children: caseStudy.name
                }));
}

var make = CaseStudySquare;

var $$default = CaseStudySquare;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* number Not a pure module */
