// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "./Theme.js";
import * as Utils$GlazedWebsite from "./shared/Utils.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flex3(1, 1, /* `rem */[
                5691738,
                0.00000001
              ]),
          /* :: */[
            Css.height(Css.pct(100)),
            /* :: */[
              Css.padding3(Css.rem(4.1875), Css.rem(1.25), /* zero */-789508312),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding3(Css.rem(6.25), Css.rem(4.75), /* zero */-789508312),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper
};

var make = React.memo((function (Props) {
        var className = Props.className;
        var children = Props.children;
        var tmp = { };
        var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              wrapper,
              /* :: */[
                className,
                /* [] */0
              ]
            ]);
        if (tmp$1 !== undefined) {
          tmp.className = Caml_option.valFromOption(tmp$1);
        }
        return React.createElement("main", tmp, children);
      }));

var $$default = make;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
