// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "./Utils.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var mask = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.height(Css.pct(100)),
          /* :: */[
            Css.top(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* :: */[
                Css.pointerEvents(/* none */-922086728),
                /* :: */[
                  Css.backgroundColor(Css.rgba(255, 255, 255, 0.35)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  mask: mask
};

function MaskContainer(Props) {
  var className = Props.className;
  var maskClassName = Props.maskClassName;
  var children = Props.children;
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = { };
  var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        mask,
        /* :: */[
          maskClassName,
          /* [] */0
        ]
      ]);
  if (tmp$3 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement("div", tmp, children, React.createElement("div", tmp$2));
}

var make = MaskContainer;

var $$default = MaskContainer;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
