// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Utils$GlazedWebsite from "../../../shared/Utils.js";
import * as Square$GlazedWebsite from "../../../Square.js";

var squareContent = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var leftBlueSquareStyles_000 = Css.before(/* :: */[
      Css.contentRule(/* `text */[
            -856044371,
            ""
          ]),
      /* :: */[
        Css.display(/* block */888960333),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* :: */[
            Css.bottom(/* `px */[
                  25096,
                  1
                ]),
            /* :: */[
              Css.left(/* `px */[
                    25096,
                    1
                  ]),
              /* :: */[
                Css.width(Css.px(5)),
                /* :: */[
                  Css.height(Css.px(5)),
                  /* :: */[
                    Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkerish)),
                    /* :: */[
                      Css.opacity(0.8),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var leftBlueSquareStyles = /* :: */[
  leftBlueSquareStyles_000,
  /* [] */0
];

function content(withCornerDot) {
  return Curry._1(Css.style, /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.flexDirection(/* column */-963948842),
                /* :: */[
                  Css.flex3(1, 1, /* `rem */[
                        5691738,
                        0.000001
                      ]),
                  /* :: */[
                    Css.padding3(/* `rem */[
                          5691738,
                          0.625
                        ], /* `rem */[
                          5691738,
                          0.9375
                        ], /* `rem */[
                          5691738,
                          0.9375
                        ]),
                    /* :: */[
                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                            Css.padding3(/* `rem */[
                                  5691738,
                                  1.25
                                ], /* `rem */[
                                  5691738,
                                  1.875
                                ], /* `rem */[
                                  5691738,
                                  1.875
                                ]),
                            /* [] */0
                          ]),
                      withCornerDot ? leftBlueSquareStyles : /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var hidden = Curry._1(Css.style, /* :: */[
      Css.visibility(/* hidden */-862584982),
      /* :: */[
        Css.opacity(0),
        /* :: */[
          Css.transitions(/* :: */[
                Css_Core.Transition.shorthand(0, 400, undefined, "visibility"),
                /* :: */[
                  Css_Core.Transition.shorthand(400, undefined, undefined, "opacity"),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var visible = Curry._1(Css.style, /* :: */[
      Css.visibility(/* visible */589592690),
      /* :: */[
        Css.opacity(1),
        /* :: */[
          Css.transition(150, undefined, undefined, "opacity"),
          /* [] */0
        ]
      ]
    ]);

var contentWhenSelected = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* :: */[
                Css.backgroundColor(Css.rgba(41, 98, 246, 0.8)),
                /* :: */[
                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueDarker)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  squareContent: squareContent,
  leftBlueSquareStyles: leftBlueSquareStyles,
  content: content,
  hidden: hidden,
  visible: visible,
  contentWhenSelected: contentWhenSelected
};

function CaseStudiesSlideSquare(Props) {
  var children = Props.children;
  var className = Props.className;
  var baseContentWrapperClassName = Props.contentWrapperClassName;
  var baseContentClassName = Props.contentClassName;
  var contentWhenSelected$1 = Props.contentWhenSelected;
  var isSelectedOpt = Props.isSelected;
  var onMouseEnter = Props.onMouseEnter;
  var isSelected = isSelectedOpt !== undefined ? isSelectedOpt : false;
  var hasContentWhenSelected = contentWhenSelected$1 !== undefined;
  var contentWrapperClassName = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        squareContent,
        /* :: */[
          baseContentWrapperClassName,
          /* [] */0
        ]
      ]);
  var contentClassName = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        isSelected ? Curry._1(Css.merge, /* :: */[
                content(hasContentWhenSelected),
                /* :: */[
                  hidden,
                  /* [] */0
                ]
              ]) : Curry._1(Css.merge, /* :: */[
                content(hasContentWhenSelected),
                /* :: */[
                  visible,
                  /* [] */0
                ]
              ]),
        /* :: */[
          baseContentClassName,
          /* [] */0
        ]
      ]);
  var contentWhenSelectedClassName = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        content(false),
        /* :: */[
          isSelected ? Curry._1(Css.merge, /* :: */[
                  contentWhenSelected,
                  /* :: */[
                    visible,
                    /* [] */0
                  ]
                ]) : Curry._1(Css.merge, /* :: */[
                  contentWhenSelected,
                  /* :: */[
                    hidden,
                    /* [] */0
                  ]
                ]),
          /* [] */0
        ]
      ]);
  var wrappedChildren;
  if (children !== undefined) {
    var tmp = { };
    if (contentClassName !== undefined) {
      tmp.className = Caml_option.valFromOption(contentClassName);
    }
    wrappedChildren = Caml_option.some(React.createElement("div", tmp, Caml_option.valFromOption(children)));
  } else {
    wrappedChildren = undefined;
  }
  var wrappedContentWhenSelected;
  if (contentWhenSelected$1 !== undefined) {
    var tmp$1 = { };
    if (contentWhenSelectedClassName !== undefined) {
      tmp$1.className = Caml_option.valFromOption(contentWhenSelectedClassName);
    }
    wrappedContentWhenSelected = Caml_option.some(React.createElement("div", tmp$1, Caml_option.valFromOption(contentWhenSelected$1)));
  } else {
    wrappedContentWhenSelected = undefined;
  }
  var childrenForSquare;
  if (wrappedContentWhenSelected !== undefined) {
    var content$1 = Caml_option.valFromOption(wrappedContentWhenSelected);
    childrenForSquare = wrappedChildren !== undefined ? Caml_option.some(React.createElement(React.Fragment, undefined, Caml_option.valFromOption(wrappedChildren), content$1)) : Caml_option.some(content$1);
  } else {
    childrenForSquare = wrappedChildren;
  }
  var tmp$2 = { };
  if (childrenForSquare !== undefined) {
    tmp$2.children = Caml_option.valFromOption(childrenForSquare);
  }
  if (className !== undefined) {
    tmp$2.className = Caml_option.valFromOption(className);
  }
  if (contentWrapperClassName !== undefined) {
    tmp$2.contentClassName = Caml_option.valFromOption(contentWrapperClassName);
  }
  if (onMouseEnter !== undefined) {
    tmp$2.onMouseEnter = Caml_option.valFromOption(onMouseEnter);
  }
  return React.createElement(Square$GlazedWebsite.make, tmp$2);
}

var make = CaseStudiesSlideSquare;

var $$default = CaseStudiesSlideSquare;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* squareContent Not a pure module */
