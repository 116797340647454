// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Heading$GlazedWebsite from "../../Heading.js";
import * as FullPageSlide$GlazedWebsite from "../../FullPageSlide.js";
import * as DeveloperBackgroundImage$GlazedWebsite from "./team-slide/DeveloperBackgroundImage.js";

var manifestoContent = [
  {
    title: "love their work",
    content: "They love talking about technology, so they know where it is at and where it is going, and they communicate programming challenges better. They have a comprehensive toolbox, and know there are no silver bullets. And that's why they pick the right tools for specific projects."
  },
  {
    title: "are great people",
    content: "They care about who they work with, and about areas outside their speciality, understanding other's needs and limitations. They anticipate problems and propose solutions. And that's why it takes less time to ship a great product with them."
  },
  {
    title: "build great code",
    content: "Great code is simple: it is the shortest path to a solution. Because it doesn't try to solve the future, it is more adaptable to the unpredictable. Because it is easily understood, it is easy to improve upon. And that's why it takes them less time to add new features."
  }
];

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.padding3(Css.rem(4.1875), Css.rem(2.5), Css.rem(6)),
            /* :: */[
              Css.transform(/* `translateZ */[
                    106728780,
                    /* zero */-789508312
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding3(Css.rem(13.5625), Css.rem(4.75), /* zero */-789508312),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var section = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var contentWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.flexDirection(/* row */5693978),
                /* :: */[
                  Css.flex3(1, 1, /* auto */-1065951377),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var funPart = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.height(Css.rem(5.5)),
        /* :: */[
          Css.position(/* absolute */-1013592457),
          /* :: */[
            Css.right(/* zero */-789508312),
            /* :: */[
              Css.bottom(/* zero */-789508312),
              /* :: */[
                Css.left(Css.rem(2.5)),
                /* :: */[
                  Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.position(/* static */100392110),
                          /* :: */[
                            Css.height(/* auto */-1065951377),
                            /* :: */[
                              Css.margin4(/* zero */-789508312, Css.rem(-4.75), /* zero */-789508312, /* zero */-789508312),
                              /* :: */[
                                Css.flex3(0, 1, Css.pct(40)),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var image = Curry._1(Css.style, /* :: */[
      Css.flex3(1, 1, Css.rem(0.0000001)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.opacity(0.3),
          /* [] */0
        ]
      ]
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.flex3(1, 1, /* auto */-1065951377),
              /* [] */0
            ]
          ]),
      /* [] */0
    ]);

var displayTitle = Curry._1(Css.style, /* :: */[
      Css.after(/* :: */[
            Css.contentRule(/* `text */[
                  -856044371,
                  ""
                ]),
            /* :: */[
              Css.position(/* absolute */-1013592457),
              /* :: */[
                Css.top(Css.rem(-2.0)),
                /* :: */[
                  Css.left(Css.rem(-1.25)),
                  /* :: */[
                    Css.width(Css.rem(8.125)),
                    /* :: */[
                      Css.height(Css.rem(8.125)),
                      /* :: */[
                        Css.border(Css.px(1), /* solid */12956715, Css.rgba(159, 168, 179, 0.1)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        Css.paddingBottom(Css.rem(1.25)),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                Css.after(/* :: */[
                      Css.display(/* none */-922086728),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.paddingLeft(Css.rem(1.25)),
                  /* :: */[
                    Css.paddingBottom(Css.rem(6.25)),
                    /* [] */0
                  ]
                ]
              ]),
          /* [] */0
        ]
      ]
    ]);

var verticalLine_000 = Css.contentRule(/* `text */[
      -856044371,
      ""
    ]);

var verticalLine_001 = /* :: */[
  Css.position(/* fixed */10615156),
  /* :: */[
    Css.top(/* zero */-789508312),
    /* :: */[
      Css.bottom(/* zero */-789508312),
      /* :: */[
        Css.marginLeft(Css.rem(-1.25)),
        /* :: */[
          Css.border(Css.px(1), /* solid */12956715, Css.rgba(159, 168, 179, 0.1)),
          /* [] */0
        ]
      ]
    ]
  ]
];

var verticalLine = /* :: */[
  verticalLine_000,
  verticalLine_001
];

var block = Curry._1(Css.style, /* :: */[
      Css.not__(":last-child", /* :: */[
            Css.marginBottom(Css.rem(1.25)),
            /* [] */0
          ]),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
              Css.position(/* relative */903134412),
              /* :: */[
                Css.flex3(1, 1, Css.pct(33)),
                /* :: */[
                  Css.padding2(Css.rem(0), Css.rem(1.25)),
                  /* :: */[
                    Css.before(verticalLine),
                    /* :: */[
                      Css.display(/* flex */-1010954439),
                      /* :: */[
                        Css.flexDirection(/* column */-963948842),
                        /* :: */[
                          Css.lastChild(/* :: */[
                                Css.after(/* :: */[
                                      Css.contentRule(/* `text */[
                                            -856044371,
                                            ""
                                          ]),
                                      /* :: */[
                                        Css.position(/* fixed */10615156),
                                        /* :: */[
                                          Css.top(/* zero */-789508312),
                                          /* :: */[
                                            Css.bottom(/* zero */-789508312),
                                            /* :: */[
                                              Css.marginLeft(Css.rem(1.25)),
                                              /* :: */[
                                                Css.alignSelf(/* flexEnd */924268066),
                                                /* :: */[
                                                  Css.border(Css.px(1), /* solid */12956715, Css.rgba(159, 168, 179, 0.1)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var pre = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.color(Css.hex("2560F2")),
        /* :: */[
          Css.fontSize(Css.rem(0.75)),
          /* :: */[
            Css.paddingBottom(Css.rem(1.125)),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                    Css.display(/* block */888960333),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var title = Curry._1(Css.style, /* :: */[
      Css.paddingBottom(Css.rem(0.625)),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGreyDarker)),
        /* :: */[
          Css.lineHeight(Css.rem(1.5)),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                  Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
                  /* :: */[
                    Css.paddingBottom(Css.rem(1.125)),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var strong = Curry._1(Css.style, /* :: */[
      Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGreyDarker)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var paragraph = Curry._1(Css.style, /* :: */[
      Css.fontWeight(/* `num */[
            5496390,
            400
          ]),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.letterSpacing(Css.rem(-0.025)),
          /* :: */[
            Css.lineHeight(Css.rem(1.5)),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
              /* :: */[
                Css.opacity(0.8),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                        Css.fontSize(Css.rem(0.875)),
                        /* :: */[
                          Css.lineHeight(Css.rem(2.125)),
                          /* [] */0
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  section: section,
  contentWrapper: contentWrapper,
  funPart: funPart,
  image: image,
  content: content,
  displayTitle: displayTitle,
  verticalLine: verticalLine,
  block: block,
  pre: pre,
  title: title,
  strong: strong,
  paragraph: paragraph
};

var make = React.memo((function (Props) {
        var innerRef = Props.innerRef;
        var id = Props.id;
        var onResize = Props.onResize;
        var tmp = {
          children: null,
          className: wrapper,
          onResize: onResize
        };
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (innerRef !== undefined) {
          tmp.innerRef = Caml_option.valFromOption(innerRef);
        }
        return React.createElement(FullPageSlide$GlazedWebsite.make, tmp, React.createElement(Heading$GlazedWebsite.make, {
                        level: /* H2 */1,
                        className: displayTitle,
                        children: "// manifesto"
                      }), React.createElement("div", {
                        className: contentWrapper
                      }, React.createElement("div", {
                            className: content
                          }, $$Array.mapi((function (index, element) {
                                  var index$1 = index + 1 | 0;
                                  return React.createElement("div", {
                                              key: String(index$1),
                                              className: block
                                            }, React.createElement(Heading$GlazedWebsite.make, {
                                                  level: /* H5 */4,
                                                  className: pre,
                                                  children: "// 0" + (String(index$1) + "")
                                                }), React.createElement(Heading$GlazedWebsite.make, {
                                                  level: /* H3 */2,
                                                  className: title,
                                                  children: null
                                                }, "Great developers ", React.createElement("strong", {
                                                      className: strong
                                                    }, element.title)), React.createElement("p", {
                                                  className: paragraph
                                                }, element.content));
                                }), manifestoContent)), React.createElement("div", {
                            className: funPart
                          }, React.createElement(DeveloperBackgroundImage$GlazedWebsite.make, {
                                developerPhotoKey: "overlay",
                                className: image
                              }))));
      }));

var $$default = make;

export {
  manifestoContent ,
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
