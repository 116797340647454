// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Utils$GlazedWebsite from "../../../shared/Utils.js";
import * as Gatsby$GlazedWebsite from "../../../shared/Gatsby.js";
import * as GatsbyBackgroundImage from "gatsby-background-image";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* [] */0
    ]);

var backgroundImage = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  backgroundImage: backgroundImage
};

var backgroundImageQuery = (Gatsby.graphql`
    query {
      matias: file(relativePath: { eq: "team/matias.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      filipe: file(relativePath: { eq: "team/filipe.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      nuno: file(relativePath: { eq: "team/nuno.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ismael: file(relativePath: { eq: "team/ismael.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      miguel: file(relativePath: { eq: "team/miguel.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      diogo: file(relativePath: { eq: "team/diogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      figo: file(relativePath: { eq: "team/figo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pedro: file(relativePath: { eq: "team/pedro.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      raspa: file(relativePath: { eq: "team/raspa.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      mariia: file(relativePath: { eq: "team/mariia.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      tania: file(relativePath: { eq: "team/tania.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      goncalo: file(relativePath: { eq: "team/goncalo.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      jose: file(relativePath: { eq: "team/jose.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      cristiano: file(relativePath: { eq: "team/cristiano.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      joao: file(relativePath: { eq: "team/joao.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      maria: file(relativePath: { eq: "team/maria.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      rick: file(relativePath: { eq: "team/rick.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      torres: file(relativePath: { eq: "team/torres.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      nelson: file(relativePath: { eq: "team/nelson.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      daniela: file(relativePath: { eq: "team/daniela.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      batista: file(relativePath: { eq: "team/batista.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      } 
      fox: file(relativePath: { eq: "team/fox.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      } 
      overlay: file(relativePath: { eq: "team/overlay.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

function DeveloperBackgroundImage(Props) {
  var developerPhotoKey = Props.developerPhotoKey;
  var className = Props.className;
  var gatsbyBackgroundImageClassName = Props.gatsbyBackgroundImageClassName;
  var children = Props.children;
  var style = Props.style;
  var queryResult = Gatsby.useStaticQuery(backgroundImageQuery);
  var backgroundImage$1 = Gatsby$GlazedWebsite.getImageFluid(queryResult, developerPhotoKey);
  var baseStyle = {
    backgroundPosition: "top center",
    marginTop: "10%",
    position: "absolute",
    backgroundSize: "auto 100%"
  };
  var backgroundImageStyle = style !== undefined ? Object.assign(({}), baseStyle, Caml_option.valFromOption(style)) : baseStyle;
  var tmp = { };
  var tmp$1 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        wrapper,
        /* :: */[
          className,
          /* [] */0
        ]
      ]);
  if (tmp$1 !== undefined) {
    tmp.className = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    style: backgroundImageStyle
  };
  if (backgroundImage$1 !== undefined) {
    tmp$2.fluid = Caml_option.valFromOption(backgroundImage$1);
  }
  var tmp$3 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
        backgroundImage,
        /* :: */[
          gatsbyBackgroundImageClassName,
          /* [] */0
        ]
      ]);
  if (tmp$3 !== undefined) {
    tmp$2.className = Caml_option.valFromOption(tmp$3);
  }
  if (children !== undefined) {
    tmp$2.children = Caml_option.valFromOption(children);
  }
  return React.createElement("div", tmp, React.createElement(GatsbyBackgroundImage.default, tmp$2));
}

var make = DeveloperBackgroundImage;

export {
  Styles ,
  backgroundImageQuery ,
  make ,
  
}
/* wrapper Not a pure module */
