// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Square$GlazedWebsite from "../../../Square.js";
import * as Heading$GlazedWebsite from "../../../Heading.js";
import * as MobileFooter$GlazedWebsite from "../../../MobileFooter.js";
import * as FullPageSlide$GlazedWebsite from "../../../FullPageSlide.js";
import * as MaskContainer$GlazedWebsite from "../../../shared/MaskContainer.js";
import * as GlitchCanvas from "src/components/glitchCanvas";
import * as GlitchImages from "src/components/glitchImages";
import * as CaseStudySquare$GlazedWebsite from "./CaseStudySquare.js";
import * as CaseStudiesSlideSquare$GlazedWebsite from "./CaseStudiesSlideSquare.js";

var footerHeight = MobileFooter$GlazedWebsite.Styles.Variables.height;

var paddingBottom_001 = footerHeight[1] + 1.25;

var paddingBottom = /* `rem */[
  5691738,
  paddingBottom_001
];

var wrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.justifyContent(/* flexEnd */924268066),
            /* :: */[
              Css.padding4(Css.rem(4.1875), Css.rem(2.5), paddingBottom, Css.rem(1.25)),
              /* :: */[
                Css.overflow(/* hidden */-862584982),
                /* :: */[
                  Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.padding4(Css.rem(6.25), Css.rem(7.25), Css.rem(1.875), Css.rem(4.75)),
                            /* :: */[
                              Css.justifyContent(/* center */98248149),
                              /* [] */0
                            ]
                          ]
                        ]),
                    /* :: */[
                      Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                            Css.padding4(Css.rem(6.25), Css.rem(16.875), Css.rem(1.875), Css.rem(8.75)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var maskContainer = Curry._1(Css.style, /* :: */[
      Css.backgroundColor(Css.rgba(34, 39, 63, 0.8)),
      /* [] */0
    ]);

var backgroundImage = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var grid = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.maxWidth(/* `rem */[
                5691738,
                31.25
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.maxWidth(/* `rem */[
                        5691738,
                        75
                      ]),
                  /* [] */0
                ]),
            /* :: */[
              Css.padding4(/* `px */[
                    25096,
                    1
                  ], /* zero */-789508312, /* zero */-789508312, /* `px */[
                    25096,
                    1
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var backgroundGrid = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* `px */[
              25096,
              1
            ]),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* `px */[
                    25096,
                    1
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var contentGrid = Curry._1(Css.style, /* :: */[
      Css.width(Css.pct(100)),
      /* [] */0
    ]);

var squareRow = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* [] */0
      ]
    ]);

var backgroundSquareRow = Curry._1(Css.merge, /* :: */[
      squareRow,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.opacity(0.7),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var dimmedBackgroundSquareRow = Curry._1(Css.merge, /* :: */[
      squareRow,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.opacity(0.3),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var onlyInMobile = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* flex */-1010954439),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var square = Curry._1(Css.style, /* :: */[
      Css.margin4(/* `px */[
            25096,
            -1
          ], /* zero */-789508312, /* zero */-789508312, /* `px */[
            25096,
            -1
          ]),
      /* :: */[
        Css.flex3(0, 0, Css.pct(50)),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.flex3(0, 0, Css.pct(25)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var squareContent = Curry._1(Css.style, /* :: */[
      Css.padding4(/* `px */[
            25096,
            1
          ], /* zero */-789508312, /* zero */-789508312, /* `px */[
            25096,
            1
          ]),
      /* [] */0
    ]);

var squareWithBorder = Curry._1(Css.merge, /* :: */[
      square,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.opacity(0.7),
              /* :: */[
                Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var headingSquareContent = Curry._1(Css.merge, /* :: */[
      squareContent,
      /* :: */[
        Curry._1(Css.style, /* :: */[
              Css.padding2(/* zero */-789508312, /* `rem */[
                    5691738,
                    0.9375
                  ]),
              /* :: */[
                Css.justifyContent(/* center */98248149),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.padding2(/* zero */-789508312, /* `rem */[
                              5691738,
                              1.4375
                            ]),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var fakeSquareContent = Curry._1(Css.merge, /* :: */[
      Curry._1(Css.style, /* :: */[
            Css.borderColor(/* transparent */582626130),
            /* [] */0
          ]),
      /* [] */0
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.opacity(0.9),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              1.125
            ]),
        /* :: */[
          Css.lineHeight(/* `rem */[
                5691738,
                1.75
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                  Css.fontSize(Css.rem(2)),
                  /* :: */[
                    Css.lineHeight(Css.rem(3)),
                    /* :: */[
                      Css.width(Css.px(183)),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  maskContainer: maskContainer,
  backgroundImage: backgroundImage,
  grid: grid,
  backgroundGrid: backgroundGrid,
  contentGrid: contentGrid,
  squareRow: squareRow,
  backgroundSquareRow: backgroundSquareRow,
  dimmedBackgroundSquareRow: dimmedBackgroundSquareRow,
  onlyInMobile: onlyInMobile,
  square: square,
  squareContent: squareContent,
  squareWithBorder: squareWithBorder,
  headingSquareContent: headingSquareContent,
  fakeSquareContent: fakeSquareContent,
  heading: heading
};

var caseStudies = [
  {
    index: 0,
    name: "LVMH",
    area: "Fashion - Chatbots",
    backgroundImageKey: "LVMH",
    link: "/case-studies/lvmh"
  },
  {
    index: 1,
    name: "Farfetch",
    area: "Fashion - Ecommerce",
    backgroundImageKey: "Farfetch",
    link: "/case-studies/farfetch"
  },
  {
    index: 2,
    name: "Mercadão",
    area: "Retail - Marketplace",
    backgroundImageKey: "Mercadao",
    link: "/case-studies/mercadao"
  },
  {
    index: 3,
    name: "Boston Hospital",
    area: "HealthTech - IoT",
    backgroundImageKey: "Boston",
    link: "/case-studies/boston"
  }
];

var make = React.memo((function (Props) {
        var innerRef = Props.innerRef;
        var id = Props.id;
        var onResize = Props.onResize;
        var match = React.useState((function () {
                return Caml_array.caml_array_get(caseStudies, 0);
              }));
        var selectCaseStudy = match[1];
        var selectedCaseStudy = match[0];
        var tmp = {
          children: null,
          className: wrapper,
          onResize: onResize
        };
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (innerRef !== undefined) {
          tmp.innerRef = Caml_option.valFromOption(innerRef);
        }
        return React.createElement(FullPageSlide$GlazedWebsite.make, tmp, React.createElement(MaskContainer$GlazedWebsite.make, {
                        className: backgroundImage,
                        maskClassName: maskContainer,
                        children: React.createElement(GlitchCanvas.default, {
                              children: React.createElement(GlitchImages.default, {
                                    current: selectedCaseStudy.index
                                  })
                            })
                      }), React.createElement("div", {
                        className: grid
                      }, React.createElement("div", {
                            className: backgroundGrid
                          }, React.createElement("div", {
                                className: dimmedBackgroundSquareRow
                              }, React.createElement(Square$GlazedWebsite.make, {
                                    className: squareWithBorder
                                  })), React.createElement("div", {
                                className: backgroundSquareRow
                              }, $$Array.mapi((function (index, param) {
                                      return React.createElement(Square$GlazedWebsite.make, {
                                                  className: squareWithBorder,
                                                  key: String(index)
                                                });
                                    }), caseStudies)), React.createElement("div", {
                                className: Curry._1(Css.merge, /* :: */[
                                      dimmedBackgroundSquareRow,
                                      /* :: */[
                                        onlyInMobile,
                                        /* [] */0
                                      ]
                                    ])
                              }, React.createElement(Square$GlazedWebsite.make, {
                                    className: square,
                                    contentClassName: fakeSquareContent
                                  }), React.createElement(Square$GlazedWebsite.make, {
                                    className: squareWithBorder
                                  }))), React.createElement("div", {
                            className: contentGrid
                          }, React.createElement("div", {
                                className: squareRow
                              }, React.createElement(CaseStudiesSlideSquare$GlazedWebsite.make, {
                                    children: React.createElement(Heading$GlazedWebsite.make, {
                                          level: /* H1 */0,
                                          className: heading,
                                          children: "// case studies"
                                        }),
                                    className: square,
                                    contentClassName: headingSquareContent
                                  })), React.createElement("div", {
                                className: squareRow
                              }, $$Array.mapi((function (index, caseStudy) {
                                      return React.createElement(CaseStudySquare$GlazedWebsite.make, {
                                                  caseStudy: caseStudy,
                                                  index: index,
                                                  className: square,
                                                  contentWrapperClassName: squareContent,
                                                  isSelected: caseStudy === selectedCaseStudy,
                                                  onMouseEnter: (function (param) {
                                                      return Curry._1(selectCaseStudy, (function (param) {
                                                                    return caseStudy;
                                                                  }));
                                                    }),
                                                  key: String(index)
                                                });
                                    }), caseStudies)), React.createElement("div", {
                                className: Curry._1(Css.merge, /* :: */[
                                      squareRow,
                                      /* :: */[
                                        onlyInMobile,
                                        /* [] */0
                                      ]
                                    ])
                              }, React.createElement(CaseStudiesSlideSquare$GlazedWebsite.make, {
                                    className: square,
                                    contentClassName: fakeSquareContent
                                  }), React.createElement(CaseStudiesSlideSquare$GlazedWebsite.make, {
                                    className: square
                                  })))));
      }));

var $$default = make;

export {
  Styles ,
  caseStudies ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
