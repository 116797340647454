// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Heading$GlazedWebsite from "../../../Heading.js";
import * as HTMLText$GlazedWebsite from "../../../shared/HTMLText.js";
import * as FullPageSlide$GlazedWebsite from "../../../FullPageSlide.js";
import * as AboutSlideProjectList$GlazedWebsite from "./AboutSlideProjectList.js";

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* grid */-999565626),
      /* :: */[
        Css.gridTemplateRows(/* :: */[
              /* auto */-1065951377,
              /* :: */[
                /* `minmax */[
                  -754859950,
                  /* tuple */[
                    /* auto */-1065951377,
                    Css.rem(11.25)
                  ]
                ],
                /* :: */[
                  /* `fr */[
                    22860,
                    1
                  ],
                  /* [] */0
                ]
              ]
            ]),
        /* :: */[
          Css.flex3(1, 0, /* auto */-1065951377),
          /* :: */[
            Css.padding4(Css.rem(4.1875), /* zero */-789508312, /* zero */-789508312, Css.rem(1.25)),
            /* :: */[
              Css.overflow(/* hidden */-862584982),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.gridTemplateRows(/* :: */[
                            /* auto */-1065951377,
                            /* :: */[
                              /* `fr */[
                                22860,
                                1
                              ],
                              /* [] */0
                            ]
                          ]),
                      /* :: */[
                        Css.gridTemplateColumns(/* :: */[
                              /* `fr */[
                                22860,
                                1
                              ],
                              /* :: */[
                                /* `fr */[
                                  22860,
                                  1
                                ],
                                /* [] */0
                              ]
                            ]),
                        /* :: */[
                          Css.padding4(Css.rem(6.25), /* zero */-789508312, /* zero */-789508312, Css.rem(4.75)),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                        Css.gridTemplateColumns(/* :: */[
                              /* `fr */[
                                22860,
                                6
                              ],
                              /* :: */[
                                /* `fr */[
                                  22860,
                                  4
                                ],
                                /* [] */0
                              ]
                            ]),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var about = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flex3(0, 0, /* auto */-1065951377),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.gridColumn(1, 3),
                  /* :: */[
                    Css.padding4(/* zero */-789508312, Css.rem(4.75), Css.rem(4.75), /* zero */-789508312),
                    /* [] */0
                  ]
                ]),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                    Css.gridColumn(1, 2),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(/* `rem */[
              5691738,
              1.875
            ], /* zero */-789508312, /* `rem */[
              5691738,
              0.625
            ], /* `rem */[
              5691738,
              0.9375
            ]),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                1.125
              ]),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
            /* :: */[
              Css.before(/* :: */[
                    Css.contentRule(/* `text */[
                          -856044371,
                          ""
                        ]),
                    /* :: */[
                      Css.display(/* block */888960333),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.top(/* `px */[
                                25096,
                                0
                              ]),
                          /* :: */[
                            Css.left(/* `px */[
                                  25096,
                                  0
                                ]),
                            /* :: */[
                              Css.width(Css.px(130)),
                              /* :: */[
                                Css.height(Css.px(130)),
                                /* :: */[
                                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
                                  /* :: */[
                                    Css.opacity(0.1),
                                    /* :: */[
                                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                            Css.width(/* `px */[
                                                  25096,
                                                  258
                                                ]),
                                            /* :: */[
                                              Css.height(/* `px */[
                                                    25096,
                                                    258
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding4(/* `rem */[
                            5691738,
                            5.625
                          ], /* zero */-789508312, /* `rem */[
                            5691738,
                            1.875
                          ], /* `rem */[
                            5691738,
                            0.9375
                          ]),
                      /* :: */[
                        Css.width(/* auto */-1065951377),
                        /* :: */[
                          Css.color(Css.hex(Theme$GlazedWebsite.Colors.darkGrey)),
                          /* :: */[
                            Css.fontSize(/* `rem */[
                                  5691738,
                                  2
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var contentText = Curry._1(Css.style, /* :: */[
      Css.padding4(/* zero */-789508312, /* `rem */[
            5691738,
            0.9375
          ], /* zero */-789508312, /* `rem */[
            5691738,
            0.9375
          ]),
      /* :: */[
        Css.opacity(0.6),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                0.75
              ]),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  2
                ]),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedGreyText)),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.display(/* block */888960333),
                      /* :: */[
                        Css.fontSize(/* `rem */[
                              5691738,
                              1.5
                            ]),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var contentTextLine = Curry._1(Css.style, /* :: */[
      Css.display(/* inline */423610969),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* block */888960333),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var dimmedOnDesktop = Curry._1(Css.style, /* :: */[
      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
            Css.opacity(0.6),
            /* [] */0
          ]),
      /* [] */0
    ]);

var htmlTextWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.display(/* block */888960333),
                /* :: */[
                  Css.padding4(Css.rem(4.75), Css.rem(2.5), /* zero */-789508312, /* zero */-789508312),
                  /* [] */0
                ]
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                  Css.gridColumn(1, 2),
                  /* :: */[
                    Css.padding4(Css.rem(4.75), Css.rem(4.75), /* zero */-789508312, Css.rem(2.5)),
                    /* :: */[
                      Css.maxWidth(Css.rem(47.5)),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var htmlTag = Curry._1(Css.style, /* :: */[
      Css.padding4(/* `rem */[
            5691738,
            0.625
          ], /* `rem */[
            5691738,
            0.9375
          ], /* `rem */[
            5691738,
            1.25
          ], /* `rem */[
            5691738,
            0.9375
          ]),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
        /* :: */[
          Css.fontSize(Css.rem(1.125)),
          /* :: */[
            Css.lineHeight(/* `abs */[
                  4845682,
                  1.5
                ]),
            /* :: */[
              Css.maxWidth(/* `rem */[
                    5691738,
                    21.875
                  ]),
              /* :: */[
                Css.textAlign(/* left */-944764921),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                        Css.maxWidth(Css.rem(30)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                          Css.maxWidth(/* none */-922086728),
                          /* :: */[
                            Css.fontSize(Css.rem(2.125)),
                            /* :: */[
                              Css.padding(/* zero */-789508312),
                              /* :: */[
                                Css.alignSelf(/* flexEnd */924268066),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  wrapper: wrapper,
  about: about,
  heading: heading,
  contentText: contentText,
  contentTextLine: contentTextLine,
  dimmedOnDesktop: dimmedOnDesktop,
  htmlTextWrapper: htmlTextWrapper,
  htmlTag: htmlTag
};

var make = React.memo((function (Props) {
        var innerRef = Props.innerRef;
        var id = Props.id;
        var onResize = Props.onResize;
        var tmp = {
          children: null,
          className: wrapper,
          onResize: onResize
        };
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (innerRef !== undefined) {
          tmp.innerRef = Caml_option.valFromOption(innerRef);
        }
        return React.createElement(FullPageSlide$GlazedWebsite.make, tmp, React.createElement("div", {
                        className: about
                      }, React.createElement(Heading$GlazedWebsite.make, {
                            level: /* H1 */0,
                            className: heading,
                            children: "// about us"
                          }), React.createElement("div", {
                            className: contentText
                          }, React.createElement("p", {
                                className: contentTextLine
                              }, "Every team member is highly experienced (8y avg), strives for excellency and has access to the colossal combined knowledge of the whole team. "), React.createElement("p", {
                                className: Curry._1(Css.merge, /* :: */[
                                      contentTextLine,
                                      /* :: */[
                                        dimmedOnDesktop,
                                        /* [] */0
                                      ]
                                    ])
                              }, "We act as (an extension of) your development team, in very flexible team sizes, and adapting to your processes and with a tight feedback loop."))), React.createElement("div", {
                        className: htmlTextWrapper
                      }, React.createElement(HTMLText$GlazedWebsite.make, {
                            tag: /* H2 */1,
                            className: htmlTag,
                            children: "It's your code, ready to hand off or build a team around at any time, but we care for it as it were ours."
                          })), React.createElement(AboutSlideProjectList$GlazedWebsite.make, { }));
      }));

var $$default = make;

export {
  Styles ,
  make ,
  $$default ,
  $$default as default,
  
}
/* wrapper Not a pure module */
