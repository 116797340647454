// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_array from "bs-platform/lib/es6/caml_array.js";
import * as Belt_SetInt from "bs-platform/lib/es6/belt_SetInt.js";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Utils$GlazedWebsite from "../../shared/Utils.js";
import * as Layout$GlazedWebsite from "../../Layout.js";
import * as PageLayout$GlazedWebsite from "../../PageLayout.js";
import * as PageContent$GlazedWebsite from "../../PageContent.js";
import * as IndexTeamSlide$GlazedWebsite from "./team-slide/IndexTeamSlide.js";
import * as IndexAboutSlide$GlazedWebsite from "./about-slide/IndexAboutSlide.js";
import * as ScrollConnectors$GlazedWebsite from "../../shared/scroll-container/ScrollConnectors.js";
import * as IndexLandingSlide$GlazedWebsite from "./IndexLandingSlide.js";
import * as IndexManifestoSlide$GlazedWebsite from "./IndexManifestoSlide.js";
import * as IndexCaseStudiesSlide$GlazedWebsite from "./case-studies-slide/IndexCaseStudiesSlide.js";

var pageLayout = Curry._1(Css.style, /* :: */[
      Css.maxHeight(Css.pct(100)),
      /* :: */[
        Css.height(Css.pct(100)),
        /* [] */0
      ]
    ]);

var wrapper = Curry._1(Css.style, /* :: */[
      Css.maxHeight(Css.pct(100)),
      /* :: */[
        Css.padding(/* zero */-789508312),
        /* :: */[
          Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlue)),
          /* :: */[
            Css.flex3(1, 1, /* `rem */[
                  5691738,
                  0.00000001
                ]),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.padding(/* zero */-789508312),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var fadeIn = Curry._1(Css.keyframes, /* :: */[
      /* tuple */[
        100,
        /* :: */[
          Css.visibility(/* visible */589592690),
          /* :: */[
            Css.opacity(1.0),
            /* [] */0
          ]
        ]
      ],
      /* [] */0
    ]);

function headerStyles(hasAnimated) {
  return Curry._1(Css.style, /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.opacity(hasAnimated ? 1 : 0),
                    /* :: */[
                      Css.visibility(hasAnimated ? /* visible */589592690 : /* hidden */-862584982),
                      /* :: */[
                        Css.animationName(fadeIn),
                        /* :: */[
                          Css.animationDuration(hasAnimated ? 0 : 1500),
                          /* :: */[
                            Css.animationDelay(hasAnimated ? 0 : 2500),
                            /* :: */[
                              Css.animationFillMode(/* forwards */733653774),
                              /* :: */[
                                Css.animationTimingFunction(/* easeOut */715748672),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* [] */0
            ]);
}

var Styles = {
  pageLayout: pageLayout,
  wrapper: wrapper,
  fadeIn: fadeIn,
  headerStyles: headerStyles
};

function usePagePositionController(numberOfSlides) {
  var positionsState = React.useState((function () {
          return Caml_array.caml_make_vect(numberOfSlides, 0);
        }));
  var setPositions = positionsState[1];
  var refs = React.useRef($$Array.init(numberOfSlides, (function (_index) {
              return React.createRef();
            })));
  var scrollerAPI = ScrollConnectors$GlazedWebsite.useClosestScrollerAPI(/* () */0);
  var domSlideRefs = $$Array.map((function (prim) {
          return prim;
        }), refs.current);
  var updatePositions = React.useCallback((function (param) {
          return $$Array.iteri((function (index, slideRef) {
                        var match = slideRef.current;
                        if (match == null) {
                          return /* () */0;
                        } else {
                          return Curry._1(setPositions, (function (state) {
                                        var nextPosition = match.getBoundingClientRect().top + Curry._1(scrollerAPI.getScrollPosition, /* () */0).scrollTop;
                                        return $$Array.mapi((function (positionIndex, position) {
                                                      if (index === positionIndex) {
                                                        return nextPosition;
                                                      } else {
                                                        return position;
                                                      }
                                                    }), state);
                                      }));
                        }
                      }), refs.current);
        }), /* tuple */[
        setPositions,
        refs,
        scrollerAPI
      ]);
  var handleResize = React.useCallback((function (_nextBoundingRect) {
          return Curry._1(updatePositions, /* () */0);
        }), [updatePositions]);
  React.useEffect((function () {
          Curry._1(updatePositions, /* () */0);
          return ;
        }), ([]));
  return /* tuple */[
          positionsState[0],
          domSlideRefs,
          handleResize
        ];
}

function useCurrentSlideIndex(positions, offsetY) {
  var scrollValues = ScrollConnectors$GlazedWebsite.useClosestScrollValues(/* () */0);
  return React.useMemo((function () {
                return Belt_Array.reduceWithIndex(positions, 0, (function (currentPage, position, index) {
                              if (scrollValues.position.scrollTop + scrollValues.boundingRect.top >= position + offsetY) {
                                return index;
                              } else {
                                return currentPage;
                              }
                            }));
              }), /* tuple */[
              scrollValues,
              positions
            ]);
}

var pagesWithDarkNavBarLinks = Belt_SetInt.fromArray([
      2,
      4
    ]);

function Index(Props) {
  var match = usePagePositionController(5);
  var onResize = match[2];
  var domSlideRefs = match[1];
  var currentPageIndex = useCurrentSlideIndex(match[0], -65);
  var useDarkNavBarLinks = Belt_SetInt.has(pagesWithDarkNavBarLinks, currentPageIndex);
  var match$1 = React.useState((function () {
          return false;
        }));
  var setHasMounted = match$1[1];
  var match$2 = Utils$GlazedWebsite.Env.getPlatform(/* () */0);
  var hasPlayedAnimation;
  if (match$2) {
    hasPlayedAnimation = false;
  } else {
    var match$3 = sessionStorage.getItem("hasPlayed:indexLanding");
    hasPlayedAnimation = match$3 !== null && match$3 === "true" ? true : false;
  }
  React.useEffect((function () {
          Curry._1(setHasMounted, (function (param) {
                  return true;
                }));
          return ;
        }), ([]));
  return React.createElement(Layout$GlazedWebsite.make, {
              children: React.createElement(PageLayout$GlazedWebsite.make, {
                    children: React.createElement(PageContent$GlazedWebsite.make, {
                          className: wrapper,
                          children: null
                        }, React.createElement(IndexLandingSlide$GlazedWebsite.make, {
                              id: "hey",
                              innerRef: Caml_array.caml_array_get(domSlideRefs, 0),
                              onResize: onResize,
                              hasMounted: match$1[0]
                            }), React.createElement(IndexCaseStudiesSlide$GlazedWebsite.make, {
                              innerRef: Caml_array.caml_array_get(domSlideRefs, 1),
                              id: "case-studies",
                              onResize: onResize
                            }), React.createElement(IndexAboutSlide$GlazedWebsite.make, {
                              innerRef: Caml_array.caml_array_get(domSlideRefs, 2),
                              id: "about",
                              onResize: onResize
                            }), React.createElement(IndexTeamSlide$GlazedWebsite.make, {
                              innerRef: Caml_array.caml_array_get(domSlideRefs, 3),
                              id: "team",
                              onResize: onResize
                            }), React.createElement(IndexManifestoSlide$GlazedWebsite.make, {
                              innerRef: Caml_array.caml_array_get(domSlideRefs, 4),
                              id: "manifesto",
                              onResize: onResize
                            })),
                    className: pageLayout,
                    headerClassName: headerStyles(hasPlayedAnimation),
                    useDarkNavBarLinks: useDarkNavBarLinks,
                    currentPageIndex: currentPageIndex
                  })
            });
}

var numberOfSlides = 5;

var headerStyleTransitionOffsetY = -65;

var make = Index;

var $$default = Index;

export {
  Styles ,
  usePagePositionController ,
  useCurrentSlideIndex ,
  pagesWithDarkNavBarLinks ,
  numberOfSlides ,
  headerStyleTransitionOffsetY ,
  make ,
  $$default ,
  $$default as default,
  
}
/* pageLayout Not a pure module */
