// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Css_Core from "bs-css/lib/es6/src/Css_Core.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as GatsbyImage from "gatsby-image";
import * as SVG$GlazedWebsite from "../../shared/SVG.js";
import * as Cube$GlazedWebsite from "./Cube.js";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Utils$GlazedWebsite from "../../shared/Utils.js";
import * as Button$GlazedWebsite from "../../shared/Button.js";
import * as Gatsby$GlazedWebsite from "../../shared/Gatsby.js";
import * as Heading$GlazedWebsite from "../../Heading.js";
import * as HTMLText$GlazedWebsite from "../../shared/HTMLText.js";
import * as Animate from "src/components/animate";
import * as GlitchText from "src/components/glitchText";
import * as FullPageSlide$GlazedWebsite from "../../FullPageSlide.js";
import * as Icon_rubySvg from "static/images/icon_ruby.svg";
import * as Icon_appleSvg from "static/images/icon_apple.svg";
import * as Icon_reactSvg from "static/images/icon_react.svg";
import * as OfficeJpg from "static/images/home/office.jpg";
import * as Icon_githubSvg from "static/images/icon_github.svg";
import * as Icon_nodejsSvg from "static/images/icon_nodejs.svg";
import * as ASOSPng from "static/images/clients/ASOS.png";
import * as Icon_androidSvg from "static/images/icon_android.svg";
import * as Icon_twitterSvg from "static/images/icon_twitter.svg";
import * as FentyPng from "static/images/clients/fenty.png";
import * as Icon_facebookSvg from "static/images/icon_facebook.svg";
import * as Icon_linkedinSvg from "static/images/icon_linkedin.svg";
import * as BulgariPng from "static/images/clients/Bulgari.png";
import * as SephoraPng from "static/images/clients/sephora.png";
import * as BloombergPng from "static/images/clients/Bloomberg.png";
import * as WeChangersPng from "static/images/clients/WeChangers.png";
import * as Icon_angular_shieldSvg from "static/images/icon_angular_shield.svg";
import * as Louis_VuittonPng from "static/images/clients/Louis_Vuitton.png";
import * as Ralph_and_russoPng from "static/images/clients/ralph_and_russo.png";
import * as BostonChildrensHospitalPng from "static/images/clients/BostonChildrensHospital.png";

var techIcons = [
  Icon_angular_shieldSvg.default,
  Icon_androidSvg.default,
  Icon_rubySvg.default,
  Icon_appleSvg.default,
  Icon_reactSvg.default,
  Icon_nodejsSvg.default
];

var socialIcons = [
  Icon_twitterSvg.default,
  Icon_facebookSvg.default,
  Icon_linkedinSvg.default,
  Icon_githubSvg.default
];

var clients = [
  ASOSPng.default,
  BostonChildrensHospitalPng.default,
  FentyPng.default,
  BulgariPng.default,
  Louis_VuittonPng.default,
  Ralph_and_russoPng.default,
  SephoraPng.default,
  WeChangersPng.default,
  BloombergPng.default
];

var mobile = Curry._1(Css.style, /* :: */[
      Css.display(/* inherit_ */-601204732),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* none */-922086728),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var tabletLandscape = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* inherit_ */-601204732),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var wrapper = Curry._1(Css.style, /* :: */[
      Css.padding2(Css.rem(8.0625), Css.rem(2.0625)),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.position(/* relative */903134412),
                /* :: */[
                  Css.overflow(/* hidden */-862584982),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.maxWidth(Css.pct(100)),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.flexDirection(/* row */5693978),
                      /* :: */[
                        Css.width(/* `rem */[
                              5691738,
                              37.5 + 2 * 8.125
                            ]),
                        /* :: */[
                          Css.height(/* `rem */[
                                5691738,
                                37.5
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var cube = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* block */888960333),
              /* :: */[
                Css.position(/* absolute */-1013592457),
                /* :: */[
                  Css.top(Css_Core.Calc.$neg(Css.pct(50), Css.px(250))),
                  /* :: */[
                    Css.left(Css_Core.Calc.$neg(Css.pct(50), Css.px(250))),
                    /* [] */0
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var logoWrapper = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* `percent */[
              -119887163,
              50
            ]),
        /* :: */[
          Css.left(/* `percent */[
                -119887163,
                50
              ]),
          /* :: */[
            Css.transform(/* `translate */[
                  -914368690,
                  /* tuple */[
                    /* `percent */[
                      -119887163,
                      -50
                    ],
                    /* `percent */[
                      -119887163,
                      -50
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var logo = Curry._1(Css.style, /* :: */[
      Css.height(Css.rem(5)),
      /* [] */0
    ]);

var pulse = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* `percent */[
              -119887163,
              50
            ]),
        /* :: */[
          Css.left(/* `percent */[
                -119887163,
                50
              ]),
          /* :: */[
            Css.transform(/* `translate */[
                  -914368690,
                  /* tuple */[
                    /* `percent */[
                      -119887163,
                      -50
                    ],
                    /* `percent */[
                      -119887163,
                      -50
                    ]
                  ]
                ]),
            /* :: */[
              Css.width(/* `px */[
                    25096,
                    1
                  ]),
              /* :: */[
                Css.height(/* `px */[
                      25096,
                      1
                    ]),
                /* :: */[
                  Css.borderRadius(/* `percent */[
                        -119887163,
                        50
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var imageForeground = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.height(Css.pct(100)),
          /* :: */[
            Css.top(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* :: */[
                Css.pointerEvents(/* none */-922086728),
                /* :: */[
                  Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.white)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var backgroundImage = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.top(/* zero */-789508312),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.bottom(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var animatedMask = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.width(Css.pct(100)),
        /* :: */[
          Css.height(Css.pct(100)),
          /* :: */[
            Css.top(/* zero */-789508312),
            /* :: */[
              Css.left(/* zero */-789508312),
              /* :: */[
                Css.pointerEvents(/* none */-922086728),
                /* :: */[
                  Css.opacity(0.65),
                  /* :: */[
                    Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueMidnight)),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var htmlText = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
      /* :: */[
        Css.fontSize(Css.rem(0.85)),
        /* :: */[
          Css.lineHeight(Css.rem(1.5)),
          /* :: */[
            Css.padding2(Css.rem(0.625), /* zero */-789508312),
            /* :: */[
              Css.maxWidth(Css.rem(13)),
              /* :: */[
                Css.top(/* `rem */[
                      5691738,
                      1.25
                    ]),
                /* :: */[
                  Css.right(/* zero */-789508312),
                  /* :: */[
                    Css.alignSelf(/* flexEnd */924268066),
                    /* :: */[
                      Css.marginRight(/* `rem */[
                            5691738,
                            -0.9375
                          ]),
                      /* :: */[
                        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                              Css.position(/* absolute */-1013592457),
                              /* :: */[
                                Css.padding(/* zero */-789508312),
                                /* :: */[
                                  Css.alignSelf(/* initial */-878767996),
                                  /* :: */[
                                    Css.marginRight(/* zero */-789508312),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var socialNetworks = Curry._1(Css.style, /* :: */[
      Css.display(/* none */-922086728),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.position(/* absolute */-1013592457),
                /* :: */[
                  Css.padding(Css.rem(4.375)),
                  /* :: */[
                    Css.bottom(/* zero */-789508312),
                    /* :: */[
                      Css.left(/* zero */-789508312),
                      /* :: */[
                        Css.margin2(Css.rem(-0.9375), /* zero */-789508312),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var socialIconLink = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.margin(Css.rem(0.9375)),
        /* [] */0
      ]
    ]);

var socialIcon = Curry._1(Css.style, /* :: */[
      Css.opacity(0.4),
      /* :: */[
        Css_Core.SVG.fill(Css.hex(Theme$GlazedWebsite.Colors.white)),
        /* [] */0
      ]
    ]);

var mainHeading = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* :: */[
          Css.fontSize(Css.rem(1.5)),
          /* :: */[
            Css.lineHeight(Css.rem(2.125)),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
              /* :: */[
                Css.height(/* `rem */[
                      5691738,
                      4.25
                    ]),
                /* :: */[
                  Css.maxWidth(Css.rem(16)),
                  /* :: */[
                    Css.overflowY(/* hidden */-862584982),
                    /* :: */[
                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                            Css.fontSize(Css.rem(2.25)),
                            /* :: */[
                              Css.lineHeight(Css.rem(2.75)),
                              /* :: */[
                                Css.height(/* `rem */[
                                      5691738,
                                      5.5
                                    ]),
                                /* :: */[
                                  Css.maxWidth(Css.rem(24)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var commentedText = Curry._1(Css.style, /* :: */[
      Css.fontWeight(/* `num */[
            5496390,
            700
          ]),
      /* :: */[
        Css.marginBottom(/* `rem */[
              5691738,
              0.25
            ]),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
          /* :: */[
            Css.opacity(0.5),
            /* :: */[
              Css.fontFamily(/* `custom */[
                    1066567601,
                    Theme$GlazedWebsite.Fonts.text
                  ]),
              /* :: */[
                Css.fontSize(Css.rem(0.75)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.fontSize(Css.rem(0.875)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var exploreCasesButtonWrapper = Curry._1(Css.style, /* :: */[
      Css.alignSelf(/* flexStart */662439529),
      /* :: */[
        Css.margin3(Css.rem(1), /* zero */-789508312, Css.rem(1.125)),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                Css.margin3(Css.rem(0.93755), /* zero */-789508312, /* zero */-789508312),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var exploreCasesButton = Curry._1(Css.style, /* :: */[
      Css.alignSelf(/* flexStart */662439529),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.height(Css.rem(3.125)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var quote = Curry._1(Css.style, /* :: */[
      Css.marginTop(Css.rem(5)),
      /* :: */[
        Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
              Css.maxWidth(Css.rem(70)),
              /* :: */[
                Css.marginTop(Css.rem(20)),
                /* :: */[
                  Css.marginBottom(Css.rem(5)),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var techStackIcons = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.position(/* absolute */-1013592457),
            /* :: */[
              Css.bottom(/* zero */-789508312),
              /* :: */[
                Css.right(/* zero */-789508312),
                /* :: */[
                  Css.left(/* zero */-789508312),
                  /* :: */[
                    Css.opacity(0.7),
                    /* :: */[
                      Css.width(/* `rem */[
                            5691738,
                            6.25
                          ]),
                      /* :: */[
                        Css.marginLeft(/* `rem */[
                              5691738,
                              -1.25
                            ]),
                        /* :: */[
                          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                Css.flex3(0, 0, /* `rem */[
                                      5691738,
                                      3.125
                                    ]),
                                /* :: */[
                                  Css.width(/* auto */-1065951377),
                                  /* :: */[
                                    Css.flexWrap(/* initial */-878767996),
                                    /* :: */[
                                      Css.marginLeft(/* zero */-789508312),
                                      /* :: */[
                                        Css.bottom(/* `rem */[
                                              5691738,
                                              5.625
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var techStackIcon = Curry._1(Css.style, /* :: */[
      Css.flex3(0, 0, Css.pct(33.333333)),
      /* :: */[
        Css.margin2(/* `rem */[
              5691738,
              0.625
            ], /* zero */-789508312),
        /* :: */[
          Css_Core.SVG.fill(Css.hex(Theme$GlazedWebsite.Colors.lightGrey)),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.flex3(0, 0, /* `rem */[
                        5691738,
                        3.125
                      ]),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var clients$1 = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.position(/* absolute */-1013592457),
        /* :: */[
          Css.flexDirection(/* column */-963948842),
          /* :: */[
            Css.right(/* zero */-789508312),
            /* :: */[
              Css.bottom(/* `rem */[
                    5691738,
                    -9
                  ]),
              /* :: */[
                Css.left(/* `rem */[
                      5691738,
                      2.375
                    ]),
                /* :: */[
                  Css.opacity(0.7),
                  /* :: */[
                    Css.maxWidth(/* `rem */[
                          5691738,
                          16
                        ]),
                    /* :: */[
                      Css.maxHeight(/* `rem */[
                            5691738,
                            7
                          ]),
                      /* :: */[
                        Css.overflow(/* hidden */-862584982),
                        /* :: */[
                          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                Css.bottom(/* `rem */[
                                      5691738,
                                      -7.5
                                    ]),
                                /* :: */[
                                  Css.left(/* `rem */[
                                        5691738,
                                        7.875
                                      ]),
                                  /* :: */[
                                    Css.maxWidth(/* `rem */[
                                          5691738,
                                          28
                                        ]),
                                    /* :: */[
                                      Css.maxHeight(/* none */-922086728),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var clientsSpan = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.lineHeight(Css.px(24)),
          /* [] */0
        ]
      ]
    ]);

var clientsImages = Curry._1(Css.style, /* :: */[
      Css.marginTop(/* `rem */[
            5691738,
            0.375
          ]),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexWrap(/* wrap */-822134326),
          /* :: */[
            Css.justifyContent(/* spaceAround */-485895757),
            /* [] */0
          ]
        ]
      ]
    ]);

var clientImage = Curry._1(Css.style, /* :: */[
      Css.width(/* auto */-1065951377),
      /* :: */[
        Css.height(Css.px(14)),
        /* :: */[
          Css.objectFit(/* contain */427065300),
          /* :: */[
            Css.marginBottom(/* `rem */[
                  5691738,
                  1
                ]),
            /* :: */[
              Css.flex3(0, 1, Css.pct(50)),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.flex3(0, 1, Css.pct(33)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var headingWrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.zIndex(0),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                    Css.marginLeft(/* `rem */[
                          5691738,
                          2.5
                        ]),
                    /* [] */0
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var skillComments = Curry._1(Css.style, /* :: */[
      Css.position(/* absolute */-1013592457),
      /* :: */[
        Css.bottom(/* `rem */[
              5691738,
              3.75
            ]),
        /* :: */[
          Css.right(/* zero */-789508312),
          /* :: */[
            Css.display(/* none */-922086728),
            /* :: */[
              Css.paddingTop(/* `rem */[
                    5691738,
                    5
                  ]),
              /* :: */[
                Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
                /* :: */[
                  Css.lineHeight(/* `abs */[
                        4845682,
                        2.4
                      ]),
                  /* :: */[
                    Css.fontFamily(/* `custom */[
                          1066567601,
                          Theme$GlazedWebsite.Fonts.heading
                        ]),
                    /* :: */[
                      Css.fontSize(/* `rem */[
                            5691738,
                            0.6875
                          ]),
                      /* :: */[
                        Css.fontWeight(/* `num */[
                              5496390,
                              700
                            ]),
                        /* :: */[
                          Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                Css.display(/* block */888960333),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles = {
  mobile: mobile,
  tabletLandscape: tabletLandscape,
  wrapper: wrapper,
  content: content,
  cube: cube,
  logoWrapper: logoWrapper,
  logo: logo,
  pulse: pulse,
  imageForeground: imageForeground,
  backgroundImage: backgroundImage,
  animatedMask: animatedMask,
  htmlText: htmlText,
  socialNetworks: socialNetworks,
  socialIconLink: socialIconLink,
  socialIcon: socialIcon,
  mainHeading: mainHeading,
  commentedText: commentedText,
  exploreCasesButtonWrapper: exploreCasesButtonWrapper,
  exploreCasesButton: exploreCasesButton,
  quote: quote,
  techStackIcons: techStackIcons,
  techStackIcon: techStackIcon,
  clients: clients$1,
  clientsSpan: clientsSpan,
  clientsImages: clientsImages,
  clientImage: clientImage,
  headingWrapper: headingWrapper,
  skillComments: skillComments
};

var imageQuery = (Gatsby.graphql`
    query {
      backgroundImage: file(relativePath: { eq: "home/office.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 4032, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

var make = React.memo((function (Props) {
        var id = Props.id;
        var innerRef = Props.innerRef;
        var onResize = Props.onResize;
        var hasMounted = Props.hasMounted;
        var queryResult = Gatsby.useStaticQuery(imageQuery);
        var backgroundImage$1 = Gatsby$GlazedWebsite.getImageFluid(queryResult, "backgroundImage");
        var match = Utils$GlazedWebsite.Env.getPlatform(/* () */0);
        var hasPlayedAnimation;
        if (match) {
          hasPlayedAnimation = false;
        } else {
          var match$1 = sessionStorage.getItem("hasPlayed:indexLanding");
          hasPlayedAnimation = match$1 !== null && match$1 === "true" ? true : false;
        }
        var tmp = {
          children: null,
          className: wrapper,
          onResize: onResize
        };
        if (id !== undefined) {
          tmp.id = Caml_option.valFromOption(id);
        }
        if (innerRef !== undefined) {
          tmp.innerRef = Caml_option.valFromOption(innerRef);
        }
        var tmp$1;
        if (hasMounted) {
          if (hasPlayedAnimation) {
            var tmp$2 = {
              className: backgroundImage,
              style: {
                position: "absolute"
              },
              loading: "eager"
            };
            if (backgroundImage$1 !== undefined) {
              tmp$2.fluid = Caml_option.valFromOption(backgroundImage$1);
            }
            tmp$1 = React.createElement(GatsbyImage.default, tmp$2);
          } else {
            tmp$1 = React.createElement("img", {
                  style: {
                    height: "100%",
                    width: "100%",
                    objectFit: "cover"
                  },
                  src: OfficeJpg.default
                });
          }
        } else {
          tmp$1 = null;
        }
        var tmp$3 = { };
        var tmp$4 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              imageForeground,
              /* :: */[
                mobile,
                /* [] */0
              ]
            ]);
        if (tmp$4 !== undefined) {
          tmp$3.className = Caml_option.valFromOption(tmp$4);
        }
        var tmp$5 = { };
        var tmp$6 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              animatedMask,
              /* :: */[
                mobile,
                /* [] */0
              ]
            ]);
        if (tmp$6 !== undefined) {
          tmp$5.className = Caml_option.valFromOption(tmp$6);
        }
        var tmp$7 = { };
        var tmp$8 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              imageForeground,
              /* :: */[
                tabletLandscape,
                /* [] */0
              ]
            ]);
        if (tmp$8 !== undefined) {
          tmp$7.className = Caml_option.valFromOption(tmp$8);
        }
        var tmp$9 = { };
        var tmp$10 = Utils$GlazedWebsite.React.combineClassNames(/* :: */[
              animatedMask,
              /* :: */[
                tabletLandscape,
                /* [] */0
              ]
            ]);
        if (tmp$10 !== undefined) {
          tmp$9.className = Caml_option.valFromOption(tmp$10);
        }
        return React.createElement(FullPageSlide$GlazedWebsite.make, tmp, React.createElement("div", {
                        className: backgroundImage
                      }, tmp$1, React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "scaleDownY",
                            baseDelay: 1250,
                            duration: 1500,
                            transformOrigin: "top center",
                            children: React.createElement("div", tmp$3)
                          }), React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "scaleUpY",
                            baseDelay: 2000,
                            duration: 1500,
                            transformOrigin: "bottom center",
                            children: React.createElement("div", tmp$5)
                          }), React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "scaleDownX",
                            baseDelay: 1250,
                            duration: 1500,
                            transformOrigin: "center right",
                            children: React.createElement("div", tmp$7)
                          }), React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "scaleUpX",
                            baseDelay: 2000,
                            duration: 1500,
                            transformOrigin: "center left",
                            children: React.createElement("div", tmp$9)
                          })), React.createElement(Animate.default, {
                        isIn: hasMounted,
                        animationGroup: "indexLanding",
                        animation: "fadeOut",
                        baseDelay: 1250,
                        duration: 200,
                        children: null
                      }, React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "pulse",
                            baseDelay: 100,
                            duration: 625,
                            iterationCount: 2,
                            timingFunction: "cubic-bezier(0, 0.75, 0, 1)",
                            children: React.createElement("div", {
                                  className: pulse
                                })
                          }), React.createElement("div", {
                            className: logoWrapper
                          }, React.createElement("svg", {
                                className: logo,
                                height: "68px",
                                width: "57px",
                                version: "1.1",
                                viewBox: "0 0 57 68",
                                xmlns: "http://www.w3.org/2000/svg"
                              }, React.createElement("g", {
                                    id: "Page-1",
                                    fill: "none",
                                    fillRule: "evenodd",
                                    stroke: "none",
                                    strokeWidth: "1"
                                  }, React.createElement("g", {
                                        id: "logo-glazed",
                                        fillRule: "nonzero",
                                        transform: "translate(-1.000000, -1.000000)"
                                      }, React.createElement("g", {
                                            id: "Group_33",
                                            transform: "translate(0.191000, 0.099000)"
                                          }, React.createElement("polygon", {
                                                id: "Path_144",
                                                fill: "#024DD1",
                                                points: "29.324 57.21 48.957 45.676 29.285 33.834 9.69 45.458"
                                              }), React.createElement("polygon", {
                                                id: "Path_145",
                                                fill: "#0E4AA0",
                                                points: "9.691 45.618 38.109 28.364 38.15 6.264 9.691 23.331"
                                              }), React.createElement("polygon", {
                                                id: "Path_146",
                                                fill: "#537EED",
                                                points: "9.691 23.326 38.15 6.26 29.328 0.901 0.81 18.084"
                                              }), React.createElement("polygon", {
                                                id: "Path_147",
                                                fill: "#0E4AA0",
                                                points: "40.155 28.567 48.955 33.834 48.955 45.676 39.071 39.726"
                                              }), React.createElement("polygon", {
                                                id: "Path_148",
                                                fill: "#2962F6",
                                                points: "20.438 29.749 29.324 35.034 29.324 45.457 20.438 40.152"
                                              }), React.createElement("polygon", {
                                                id: "Path_149",
                                                fill: "#2962F6",
                                                points: "29.324 35.034 29.324 45.457 48.957 33.833 48.957 45.675 29.324 57.21 9.691 45.457 9.691 23.326 0.861 18.019 0.861 50.947 29.324 67.967 57.787 50.92 57.787 17.987"
                                              }), React.createElement("polygon", {
                                                id: "Path_150",
                                                fill: "#537EED",
                                                points: "29.37 35.034 57.829 17.967 49.007 12.608 20.489 29.791"
                                              }))))))), React.createElement(Animate.default, {
                        isIn: hasMounted,
                        animationGroup: "indexLanding",
                        animation: "scaleUp",
                        baseDelay: 1250,
                        duration: 1500,
                        children: React.createElement(Cube$GlazedWebsite.make, {
                              className: cube
                            })
                      }), React.createElement("div", {
                        className: content
                      }, React.createElement("div", {
                            className: headingWrapper
                          }, React.createElement(Animate.default, {
                                isIn: hasMounted,
                                animationGroup: "indexLanding",
                                animation: "fadeIn",
                                baseDelay: 2250,
                                duration: 1500,
                                timingFunction: "ease-in",
                                children: React.createElement("span", {
                                      className: commentedText
                                    }, "// Some projects")
                              }), React.createElement(Heading$GlazedWebsite.make, {
                                level: /* H1 */0,
                                className: mainHeading,
                                children: hasPlayedAnimation ? React.createElement(React.Fragment, undefined, "require elite mobile and web developers") : React.createElement(GlitchText.default, {
                                        animationGroup: "indexLanding",
                                        text: "require elite mobile and web developers",
                                        baseDelay: 2500,
                                        itemDelay: 25,
                                        duration: 120,
                                        iterationCount: 35
                                      })
                              }), React.createElement("div", {
                                className: exploreCasesButtonWrapper
                              }, React.createElement(Gatsby.Link, {
                                    to: "/#case-studies",
                                    className: "",
                                    children: React.createElement(Animate.default, {
                                          isIn: hasMounted,
                                          animationGroup: "indexLanding",
                                          animation: "fadeIn",
                                          baseDelay: 2750,
                                          duration: 1000,
                                          timingFunction: "ease-in",
                                          children: React.createElement(Button$GlazedWebsite.make, {
                                                children: "> explore cases",
                                                type: /* Primary */0,
                                                className: exploreCasesButton
                                              })
                                        })
                                  }))), React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "fadeIn",
                            baseDelay: 3000,
                            duration: 750,
                            timingFunction: "ease-in",
                            children: React.createElement(HTMLText$GlazedWebsite.make, {
                                  tag: /* P */2,
                                  className: htmlText,
                                  children: "a team of highly specialized developers that takes on the most demanding web and mobile development projects"
                                })
                          }), React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "fadeIn",
                            baseDelay: 3500,
                            duration: 750,
                            timingFunction: "ease-in",
                            children: React.createElement("pre", {
                                  className: skillComments
                                }, "\n              {\n              // machine learning\n              // backend\n              // frontend\n              // android\n              // iOS\n              // APIs\n              ... }\n            ")
                          }), React.createElement("div", {
                            className: techStackIcons
                          }, React.createElement(Animate.default, {
                                isIn: hasMounted,
                                animationGroup: "indexLanding",
                                animation: "slideUpFadeIn",
                                baseDelay: 3500,
                                itemDelay: 200,
                                duration: 1500,
                                children: $$Array.mapi((function (index, icon) {
                                        return React.createElement(SVG$GlazedWebsite.make, {
                                                    className: techStackIcon,
                                                    height: "16",
                                                    asset: icon,
                                                    key: String(index)
                                                  });
                                      }), techIcons)
                              })), React.createElement("div", {
                            className: clients$1
                          }, React.createElement(Animate.default, {
                                isIn: hasMounted,
                                animationGroup: "indexLanding",
                                animation: "fadeIn",
                                baseDelay: 3750,
                                itemDelay: 200,
                                duration: 1500,
                                timingFunction: "ease-in",
                                children: React.createElement("span", {
                                      className: clientsSpan
                                    }, "clients like:")
                              }), React.createElement("div", {
                                className: clientsImages
                              }, React.createElement(Animate.default, {
                                    isIn: hasMounted,
                                    animationGroup: "indexLanding",
                                    animation: "slideUpFadeIn",
                                    baseDelay: 2750,
                                    itemDelay: 200,
                                    duration: 1000,
                                    children: $$Array.mapi((function (index, src) {
                                            return React.createElement("img", {
                                                        key: String(index),
                                                        className: clientImage,
                                                        src: src
                                                      });
                                          }), clients)
                                  })))), React.createElement("div", {
                        className: socialNetworks
                      }, React.createElement(Animate.default, {
                            isIn: hasMounted,
                            animationGroup: "indexLanding",
                            animation: "slideUpFadeIn",
                            baseDelay: 2000,
                            itemDelay: 200,
                            duration: 1000,
                            children: null
                          }, React.createElement("a", {
                                className: socialIconLink,
                                href: "https://twitter.com/glazedSolutions",
                                rel: "nofollow",
                                target: "_blank"
                              }, React.createElement(SVG$GlazedWebsite.make, {
                                    className: socialIcon,
                                    height: "16",
                                    asset: Icon_twitterSvg.default
                                  })), React.createElement("a", {
                                className: socialIconLink,
                                href: "https://www.facebook.com/glazedEliteDevelopers/",
                                rel: "nofollow",
                                target: "_blank"
                              }, React.createElement(SVG$GlazedWebsite.make, {
                                    className: socialIcon,
                                    height: "16",
                                    asset: Icon_facebookSvg.default
                                  })), React.createElement("a", {
                                className: socialIconLink,
                                href: "https://www.linkedin.com/company/glazed-solutions/",
                                rel: "nofollow",
                                target: "_blank"
                              }, React.createElement(SVG$GlazedWebsite.make, {
                                    className: socialIcon,
                                    height: "16",
                                    asset: Icon_linkedinSvg.default
                                  })), React.createElement("a", {
                                className: socialIconLink,
                                href: "https://github.com/glazed-elite-developers",
                                rel: "nofollow",
                                target: "_blank"
                              }, React.createElement(SVG$GlazedWebsite.make, {
                                    className: socialIcon,
                                    height: "16",
                                    asset: Icon_githubSvg.default
                                  })))));
      }));

var cubeOuterEdge = 37.5;

var cubeContentProjectionLength = 8.125;

var $$default = make;

export {
  techIcons ,
  socialIcons ,
  clients ,
  cubeOuterEdge ,
  cubeContentProjectionLength ,
  Styles ,
  imageQuery ,
  make ,
  $$default ,
  $$default as default,
  
}
/* techIcons Not a pure module */
