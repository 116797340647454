// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Theme$GlazedWebsite from "../../../Theme.js";
import * as Heading$GlazedWebsite from "../../../Heading.js";

var row = Curry._1(Css.style, /* :: */[
      Css.paddingLeft(/* `rem */[
            5691738,
            0.9375
          ]),
      /* :: */[
        Css.display(/* flex */-1010954439),
        /* :: */[
          Css.flexDirection(Css.row),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
            /* :: */[
              Css.opacity(0.8),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                      Css.padding2(Css.rem(0.625), Css.rem(1.875)),
                      /* [] */0
                    ]),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.padding2(Css.rem(1), Css.rem(1.875)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var rowLeft = Curry._1(Css.style, /* :: */[
      Css.opacity(0.3),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              0.625
            ]),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                Css.fontSize(Css.rem(0.625)),
                /* [] */0
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.fontSize(Css.rem(0.75)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var rowRight = Curry._1(Css.style, /* :: */[
      Css.paddingLeft(Css.rem(0.9375)),
      /* :: */[
        Css.fontSize(Css.rem(0.75)),
        /* :: */[
          Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                Css.paddingLeft(Css.rem(2.0625)),
                /* :: */[
                  Css.fontSize(Css.rem(1)),
                  /* :: */[
                    Css.fontWeight(/* `num */[
                          5496390,
                          700
                        ]),
                    /* [] */0
                  ]
                ]
              ]),
          /* :: */[
            Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                  Css.paddingLeft(Css.rem(2.0625)),
                  /* :: */[
                    Css.fontSize(Css.rem(1.125)),
                    /* :: */[
                      Css.fontWeight(/* `num */[
                            5496390,
                            700
                          ]),
                      /* [] */0
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var Styles = {
  row: row,
  rowLeft: rowLeft,
  rowRight: rowRight
};

function AboutSlideProjectList$LineProjectElement(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: row
            }, React.createElement("div", {
                  className: rowLeft
                }, "<li>"), React.createElement("div", {
                  className: rowRight
                }, children));
}

var LineProjectElement = {
  Styles: Styles,
  make: AboutSlideProjectList$LineProjectElement
};

var kindsOfProjects = [
  "Technically complex",
  "Highly customised and detailed",
  "Innovative and using recent technologies",
  "Critically impact on businesses or people's lives"
];

var wrapper = Curry._1(Css.style, /* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* :: */[
          Css.flex3(1, 1, /* `rem */[
                5691738,
                0.00000001
              ]),
          /* :: */[
            Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
            /* :: */[
              Css.lineHeight(/* `rem */[
                    5691738,
                    1.875
                  ]),
              /* :: */[
                Css.paddingBottom(Css.rem(1.25)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                        Css.paddingBottom(Css.rem(3.75)),
                        /* [] */0
                      ]),
                  /* :: */[
                    Css.media(Theme$GlazedWebsite.Breakpoints.desktop, /* :: */[
                          Css.gridRow(1, 3),
                          /* :: */[
                            Css.gridColumn(2, 3),
                            /* :: */[
                              Css.alignSelf(/* flexEnd */924268066),
                              /* :: */[
                                Css.height(Css.pct(80)),
                                /* :: */[
                                  Css.padding4(/* zero */-789508312, Css.rem(5), Css.rem(10), /* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.padding4(/* `rem */[
            5691738,
            1.25
          ], /* zero */-789508312, /* `rem */[
            5691738,
            0.625
          ], /* `rem */[
            5691738,
            3.5
          ]),
      /* :: */[
        Css.fontSize(/* `rem */[
              5691738,
              0.625
            ]),
        /* :: */[
          Css.color(Css.hex(Theme$GlazedWebsite.Colors.almostWhite)),
          /* :: */[
            Css.opacity(0.3),
            /* :: */[
              Css.media(Theme$GlazedWebsite.Breakpoints.tabletPortrait, /* :: */[
                    Css.padding3(/* `rem */[
                          5691738,
                          3.75
                        ], Css.rem(5), /* `rem */[
                          5691738,
                          0.625
                        ]),
                    /* :: */[
                      Css.fontSize(Css.rem(0.75)),
                      /* [] */0
                    ]
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding3(Css.rem(5), Css.rem(5), Css.rem(4)),
                      /* :: */[
                        Css.fontSize(Css.rem(0.8125)),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var Styles$1 = {
  wrapper: wrapper,
  heading: heading
};

function AboutSlideProjectList(Props) {
  return React.createElement("div", {
              className: wrapper
            }, React.createElement(Heading$GlazedWebsite.make, {
                  level: /* H6 */5,
                  className: heading,
                  children: "Our kind of projects:"
                }), $$Array.mapi((function (index, element) {
                    return React.createElement(AboutSlideProjectList$LineProjectElement, {
                                children: element,
                                key: String(index)
                              });
                  }), kindsOfProjects));
}

var make = AboutSlideProjectList;

export {
  LineProjectElement ,
  kindsOfProjects ,
  Styles$1 as Styles,
  make ,
  
}
/* row Not a pure module */
